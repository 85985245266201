import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ReactDOMServer from "react-dom/server";
import GjsEditor, {
  AssetsProvider,
  Canvas,
  ModalProvider,
} from "@grapesjs/react";
import { uploadImageToS3Bucket } from "../actions/s3bucket";
import plugin from "grapesjs-tui-image-editor";
import {
  MAIN_BORDER_COLOR,
  faqChecked,
  removeCroppedFromImageName,
} from "./library/common";
import CustomModal from "./library/CustomModal";
import CustomAssetManager from "./library/CustomAssetManager";
import Topbar from "./library/Topbar";
import RightSidebar from "./library/RightSidebar";
import {
  getSavedVariant,
} from "../actions/grapesjs";
import Icon from "@mdi/react";
import {
  mdiClose,
  mdiPencil,
  mdiRotateRight,
  mdiRotateLeft,
  mdiArrowExpand,
  mdiPlus,
  mdiLink,
  // mdiStarShooting,
} from "@mdi/js";
import { useParams, useLocation } from "react-router-dom";
import Modal from "react-modal";
import "./builder.css";
import { selectedClasses } from "./constants/classesData";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { getGeneratedImages, getImageProgress, removeBackground } from "../actions/image";
import CustomGenerateImageModal from "./library/CustomGenerateImageModal";
import CustomAddBackgroundModal from "./library/CustomAddBackgroundModal";
import { checkPlaceholderExists } from "./constants/jsonData";
import CustomUploadImageModal from "./library/CustomUploadImageModal";
import { ButtonLoaderJoxy, PageLoaderJoxy } from "./library/LoaderJoxy";
import { useBeforeunload } from 'react-beforeunload';
import Swal from "sweetalert2";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import CustomUploadVideoModal from "./library/CustomUploadVideoModal";
import CustomEditImagePropModal from "./library/CustomEditImagePropModal";
import CustomButtonLinkModal from "./library/CustomButtonLinkModal";
import CustomAddSectionModal from "./library/CustomAddSectionModal";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getUserSubscriptionStatus } from "../actions/auth";


Modal.setAppElement("#root");

export default function WebBuilder() {
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [showSidebar, setSidebar] = useState(true);
  const token = localStorage.getItem("user");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [cropperWidth, setCropperWidth] = useState("300");
  const [cropperHeight, setCropperHeight] = useState("200");
  const [cropperAspectRatio, setCropperAspectRatio] = useState(1);
  const [croppedImageDataUrl, setCroppedImageDataUrl] = useState(null);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const [cropperLoaded, setCropperLoaded] = useState(false);
  const [rangeval, setRangeval] = useState(0.1);
  const [previewImageStyle, setPreviewImageStyle] = useState({
    width: "285px",
    height: "285px",
  });
  const [initialZoomRatio, setInitialZoomRatio] = useState(1);

  const [removeBackgroundLoader, setRemoveBackgroundLoader] = useState(false);
  const [addBackground, setAddBackground] = useState(false);
  const [openAddBackgroundModal, setOpenAddBackgroundModal] = useState(false);
  const [addBackgroundImageUrl, setAddBackgroundImageUrl] = useState(null);
  const [openAddSectionModal, setOpenAddSectionModal] = useState(false);

  const [originalImage, setOriginalImage] = useState(null);
  const [showImageList, setShowImageList] = useState(false);
  const [assets, setAssets] = useState([]);
  const [moreImgLoading, setMoreImgLoading] = useState(false);
  const [isCreateImageModalOpen, setIsCreateImageModalOpen] = useState(false);
  const [savingImage, setSavingImage] = useState(false);
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false);
  const [isPlaceholderImage, setIsPlaceholderImage] = useState(false);
  const [messageId, setMessageId] = useState(null);
  const [aiImages, setAiImages] = useState([]);
  const user = useSelector((state) => state.auth);
  const [expandedSection, setExpandedSection] = useState('saved');

  const [openEditImagePropModal, setOpenEditImagePropModal] = useState(false);
  const [openButtonLinkModal, setOpenButtonLinkModal] = useState(false);

  const [isUploadVideoModalOpen, setIsUploadVideoModalOpen] = useState(false);
  const targetClasses = ["about_us","benefits", "work","review","service","faq","footer","gallery_1","gallery_2","gallery_3","gallery_4","gallery_5","pricing_1","pricing_2","pricing_3","sponsor-3","sponsor-2","sponsor-1"];
  const [websiteDeleted,setWebsiteDeleted] = useState(false);
  const selectedHoverClasses = ["header_second_inner","testimonial_outer","header_img_bg","about-image-bg","star_icon","benefits-image-bg","benefits-image-reverse-bg","testimonial_gradient","header_second_inner_bg_gradiant","header_circle_outer_layer","gradiant-square-top","gradiant-square-bottom", "header_circle_outer_layer_rev"];
  let selectedTextData = "";
  let selectedTextCount = true;
  let selectionData = "";

  useBeforeunload((event) => {
    if (user.editor_change_status) {
      event.preventDefault();
      event.returnValue = "Changes are not saved. Would you like to save the changes?";
    }
  });

  useEffect(() => {
    dispatch(getUserSubscriptionStatus())
      .then((res) => {
        const subscription = res.data;
        console.log(subscription);
        if (subscription && new Date(subscription.endDate) > new Date() && subscription.status === "active") {
          
        } else {
          navigate("/subscribe", { replace: true });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  })

  // Function to extract query parameter from the URL
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };
  // Extract the v_id from the query string
  const variantId = getQueryParam("v_id");

  // Parse the JSON string to get the object
  const userData = JSON.parse(token);

  const gjsOptions = {
    // height: "100vh",
    storageManager: false,
    // undoManager: { trackSelection: false },
    selectorManager: { componentFirst: true },
    assetsManager: {
      onSelect: (asset) => {
        const selectedImage = editorRef.current.getSelected();
        if (selectedImage) {
          selectedImage.set({
            attributes: {
              alt: asset.get("name"),
              src: asset.get("src"),
              title: asset.get("name"),
              original: asset.get("src"),
            },
            resizable: true,
          });
          editorRef.current.Modal.close();
        } else {
          console.error("No image selected in the editor");
        }
      },
    },
    projectData: {
      assets: [],
      pages: [
        {
          // name: 'Home page',
          component: `<div style="text-align:center; margin-top:50vh;"><img  style="height:70px;" src="/images/new_theme/Finalize-Loading-Animation.gif" alt="loader" /></div>`,
          // component: (
          //   <div>
          //     <h1 className="text-black">erstyuiop</h1>
          //     <LoopOutlinedIcon className="rotateIcon" />
          //   </div>
          // ),
        },
      ],
    },
    plugins: [plugin],
    pluginsOpts: {
      [plugin]: {
        onApply: async (imageEditor, imageModel) => {
          const base64Data = await imageEditor.toDataURL();
          if (base64Data) {
            dispatch(
              uploadImageToS3Bucket({ file: base64Data, folder_name: id })
            )
              .then(async (response) => {
                if (response?.data?.file) {
                  const selectedImage = editorRef.current.getSelected();
                  if (selectedImage) {
                    selectedImage.set("src", response?.data?.file);
                    editorRef.current.Modal.close();
                  } else {
                    console.error("No image selected in the editor");
                  }
                }
              })
              .catch((error) => {
                // Handle error as needed
                console.log("Error in fetching s3 bucket images", error);
              });
          }
        },
      },
    },
  };

  const toggleRightSidebar = (value) => {
    setSidebar(value);
  };

  // Access the "id" property from the user object
  const userId = userData.user.id;

  const onEditor = (editorInstance) => {
    window.editor = editorInstance;
    editorRef.current = editorInstance;
    window.editor.Keymaps.removeAll();
    // window.editor.setComponents(`<div style="text-align:center; margin-top:50vh;><img src="/images/Iphone-spinner-2.gif" alt="loader" /></div>`);
    dispatch(
      getSavedVariant({
        id: id,
        variantId: variantId,
      })
    )
      .then(async (response) => {
        if(response?.data?.status === 401){
          navigate("/errorPage", { replace: true });
        } 
        setWebsiteDeleted(false); 
        if (response?.data?.assets_images) {
          window.editor.AssetManager.add(response?.data?.assets_images);
        }
        window.editor.setComponents(response?.data?.html);
        window.editor.setStyle(response?.data?.css);

        const updateDeviceWidth = (deviceName, newWidth) => {
          const device = window.editor.Devices.get(deviceName);
          if (device) {
            device.set("width", newWidth);
            device.set("name", "Mobile");
          } else {
            console.log(`${deviceName} not found`);
          }
        };

        // const deviceManager = window.editor.Devices;
        // deviceManager.select("mobilePortrait");
      
          window.editor.Devices.remove("mobileLandscape");
     

        // Update the width for 'mobilePortrait' to 480px
        updateDeviceWidth("Mobile portrait", "370px");
      

        window.editor.getComponents().forEach((component) => {
          component.onAll((component) => {
              component.set("draggable", false);
              component.set("hoverable", false);
              component.set("badgable", false);
              component.set("selectable", false);
            if(component.get("tagName") === "span"){
              component.set("selectable", true);
              component.set("hoverable", true); 
            }
              
              if(component.get("tagName") === "section"|| component.get("type") === "link" ){
                component.set("selectable", true);
                component.set("hoverable", true);
              }

            if(component.get("tagName") === "iframe" || component.get("type") === "iframe"){
              component.set("selectable", true);
              component.set("hoverable", true);
              component.set("resizable", false);
            }

            if (component.get("tagName") === "img") {
              component.set("selectable", true);
              component.set("hoverable", true);
              component.set("resizable", false);
            }

            if (component.get("tagName") === "div") {
              if (component.getClasses().includes("facebook-icon")){
                component.set("selectable", false);
                component.set("hoverable", false);
              }

              
              const classes = component.getClasses(); // Get the array of classes
              const hasClass = selectedHoverClasses.some(cls => classes.includes(cls));
          
              // Check if the component includes any of the target classes
              const hasTargetClass = targetClasses.some(cls => classes.includes(cls));
          
              if (hasTargetClass || hasClass) {
                  component.set("selectable", true);
                  component.set("hoverable", true);
              }
          }

            if (
              component.getClasses().includes("gallery_3_image") ||
              component.getClasses().includes("gallery_1_image") ||
              component.getClasses().includes("gallery_4_image") ||
              component.getClasses().includes("gallery_5_image") ||
              component.getClasses().includes("header_img_circle") ||
              component.getClasses().includes("testimonial-quotes-img") ||
              component.getClasses().includes("benefits-wave-img") ||
              component.getClasses().includes("empty-reactangle-blue") ||
              component.getClasses().includes("reactangle-blue") ||
              component.getClasses().includes("reactangle-pink") ||
              component.getClasses().includes("empty-reactangle-pink")||
              component.getClasses().includes("header_circle_outer_layer_img") || 
              component.getClasses().includes("top-quote") ||
              component.getClasses().includes("bottom-quote") 
            ) {
              component.set("draggable", false);
              component.set("hoverable", false);
              component.set("badgable", false);
              // component.set("selectable", false);
            }
            if (component.get("type") === "text") {
              component.set("selectable", true);
              component.set("hoverable", true);
            }
            const attributeData = component.getClasses();
            const classArray = selectedClasses;

            for (let i = 0; i < attributeData.length; i++) {
              if (classArray.includes(attributeData[i])) {
                component.set("selectable", true);
                component.set("hoverable", true);
              }
            }
          });
        });
        faqChecked(window.editor);

        // Define a custom command to replace <img> with <video>
        window.editor.Commands.add('replace-img-with-video', {
          run(editor, sender, opts) {
            const component = editor.getSelected();
            var uploadUrl = opts.uploadUrl; // URL provided for video
        
            // Check if the selected component is an image and uploadUrl is provided
            if (component && uploadUrl) {
              // Get the class attribute from the old component
              const oldClass = component.getAttributes().class || '';
        
              // Get the parent of the selected component
              const parent = component.parent();

              uploadUrl = uploadUrl.replace(/<(\w+)([^>]*)>/, `<$1 class="${oldClass}"$2>`);
        
              // Create a new <video> component with the provided attributes
              const videoComponent = editor.addComponents(uploadUrl);
        
              // Insert the new component into the parent, at the same position as the old one
              parent.append(videoComponent);
        
              // Remove the old component
              component.remove();
              
              // Optionally select the new component
              editor.select(videoComponent);
            } else {
              console.error('Component is not an image or upload URL is not provided');
            }
          }
        });


        // Define a custom command to replace <img> with <video>
        window.editor.Commands.add('replace-video-with-img', {
          run(editor, sender, opts) {
            const component = editor.getSelected();
            var uploadUrl = opts.uploadUrl; // URL provided for video
        
            // Check if the selected component is an image and uploadUrl is provided
            if (component && uploadUrl) {
              // Get the class attribute from the old component
              const oldClass = component.getAttributes().class || '';
        
              // Get the parent of the selected component
              const parent = component.parent();
              uploadUrl = uploadUrl.replace(/<(\w+)([^>]*)>/, `<$1 class="${oldClass}"$2>`);
        
              // Create a new <img> component with the provided attributes
              const imageComponent = editor.addComponents( uploadUrl );
        
              // Insert the new component into the parent, at the same position as the old one
              parent.append(imageComponent);
        
              // Remove the old component
              component.remove();
              
              // Optionally select the new component
              editor.select(imageComponent);
            } else {
              console.error('Component is not an image or upload URL is not provided');
            }
          }
        });



        window.editor.on("component:selected", () => {
          const selectedComponent = window.editor.getSelected();
          const classes = selectedComponent.getClasses();
          
          // Get the DOM element of the selected component
          const selectedElement = selectedComponent.view.el;

          // if selected component is img then set aspect ratio
          if (selectedComponent.get("type") === "image" || selectedComponent.attributes.tagName === "img") {
            // Get the bounding rectangle of the selected element
            const rect = selectedElement.getBoundingClientRect();
            // Calculate the ratio (width / height)
            setCropperWidth(rect.width);
            setCropperHeight(rect.height);
            const ratio = rect.width / rect.height;
            setSelectedImage(selectedComponent.attributes.src);

            if (
              selectedComponent.getClasses().includes("main-logo") ||
              selectedComponent.getClasses().includes("footer-logo")||
              selectedComponent.getClasses().includes("sponsor_image_1")||
              selectedComponent.getClasses().includes("sponsor_image_2")||
              selectedComponent.getClasses().includes("sponsor_image_3")
            ) {
              setCropperAspectRatio(NaN);
            } else {
              setCropperAspectRatio(ratio);
            }
          }

          // whenever a component is selected in the editor

          const placeholderStatus = selectedComponent.attributes.src
            ? checkPlaceholderExists(selectedComponent.attributes.src)
            : false;
          setIsPlaceholderImage(placeholderStatus);
          addEditIcon(placeholderStatus);
          addSectionIcon();
          addLinkIcon();
         

          if (
            selectedComponent &&
            (selectedComponent.get("type") === "image" || selectedComponent.attributes.tagName === "iframe"|| selectedComponent.attributes.tagName === "img")&&
            !selectedComponent.getClasses().includes("review-quote-img") &&
            !selectedComponent.getClasses().includes("header_img_circle") &&
            !selectedComponent.getClasses().includes("testimonial-quotes-img") &&
            !selectedComponent.getClasses().includes("benefits-wave-img") &&
            !selectedComponent.getClasses().includes("empty-reactangle-blue") &&
            !selectedComponent.getClasses().includes("reactangle-blue") &&
            !selectedComponent.getClasses().includes("reactangle-pink") &&
            !selectedComponent.getClasses().includes("empty-reactangle-pink")&&
            !selectedComponent.getClasses().includes("header_circle_outer_layer_img") && 
            !selectedComponent.getClasses().includes("top-quote") &&
            !selectedComponent.getClasses().includes("bottom-quote") 
          ) {
            if(selectedComponent.getClasses().includes("social_icon")){
            const defaultToolbar = selectedComponent.get("toolbar");
            // Iterate through each toolbar item
            const updatedToolbar = defaultToolbar.map((item) => {
              // Check if the item does not belong to the TUI Image Editor command
              if (
                item.command !== "edit-image" && item.command !== "add-link"
              ) {
                // Add a custom class to the toolbar item
                return {
                  ...item,
                  attributes: {
                    style: "display: none;",
                    ...(item.attributes || {}),
                  },
                };
              } else {
                return item;
              }
            });
            // Update the component's toolbar with the modified toolbar
            selectedComponent.set({
              toolbar: updatedToolbar,
            });
          }else{
            const defaultToolbar = selectedComponent.get("toolbar");
            // Iterate through each toolbar item
            const updatedToolbar = defaultToolbar.map((item) => {
              // Check if the item does not belong to the TUI Image Editor command
              if (
                item.command !== "edit-image"
              ) {
                // Add a custom class to the toolbar item
                return {
                  ...item,
                  attributes: {
                    style: "display: none;",
                    ...(item.attributes || {}),
                  },
                };
              } else {
                return item;
              }
            });
            // Update the component's toolbar with the modified toolbar
            selectedComponent.set({
              toolbar: updatedToolbar,
            });
          }
          }else if (targetClasses.some(cls => classes.includes(cls)))  {
           
            const defaultToolbar = selectedComponent.get("toolbar");
            // Iterate through each toolbar item
            const updatedToolbar = defaultToolbar.map((item) => {
              if (
                item.command !== "add-section"  &&  item.command !== "tlb-delete"
              ) {
                // Add a custom class to the toolbar item
                return {
                  ...item,
                  attributes: {
                    style: "display: none;",
                    ...(item.attributes || {}),
                  },
                };
              } else {
                return item;
              }
            });
            // Update the component's toolbar with the modified toolbar
            selectedComponent.set({
              toolbar: updatedToolbar,
            });
        }
        
           else if (
            selectedComponent &&
            (targetClasses.some(cls => classes.includes(cls))  || selectedComponent.get("type") === "image" || selectedComponent.attributes.tagName === "iframe"|| selectedComponent.attributes.tagName === "img") &&
            (selectedComponent.getClasses().includes("review-quote-img") ||
              selectedComponent.getClasses().includes("header_img_circle") ||
              selectedComponent.getClasses().includes("testimonial-quotes-img") ||
              selectedComponent.getClasses().includes("benefits-wave-img") ||
              selectedComponent
                .getClasses()
                .includes("empty-reactangle-blue") ||
              selectedComponent.getClasses().includes("reactangle-blue") ||
              selectedComponent.getClasses().includes("reactangle-pink") ||
              selectedComponent.getClasses().includes("empty-reactangle-pink")||
              selectedComponent.getClasses().includes("header_circle_outer_layer_img") || 
              selectedComponent.getClasses().includes("top-quote") ||
              selectedComponent.getClasses().includes("bottom-quote") )
          ) {
            selectedComponent.set({
              toolbar: [],
              resizable: false,
              editable: false,
              selectable: false,
            });
          } else
          if(selectedComponent.get("type") === "link" || selectedComponent.get("tagName") === "a"){
            console.log("selectedComponent type",selectedComponent.get("type"));
            const defaultToolbar = selectedComponent.get("toolbar");
            // Iterate through each toolbar item
            const updatedToolbar = defaultToolbar.map((item) => {
              // Check if the item does not belong to the TUI Image Editor command
              if (
                item.command !== "add-link"
              ) {
                // Add a custom class to the toolbar item
                return {
                  ...item,
                  attributes: {
                    style: "display: none;",
                    ...(item.attributes || {}),
                  },
                };
              } else {
                return item;
              }
            });
            // Update the component's toolbar with the modified toolbar
            selectedComponent.set({
              toolbar: updatedToolbar,
            });
          } else {
            selectedComponent.set({
              toolbar: [],
              resizable: false,
              editable: true,
            });
          }
        });

        editorRef.current.on("component:remove", (model) => {
          dispatch({ type: 'EDITOR_CHANGE_STATUS', payload: true });
        })

        editorRef.current.on("component:input", (model) => {
          if (model.attributes.type === "text" || model.attributes.type === "label") {
            dispatch({ type: 'EDITOR_CHANGE_STATUS', payload: true });
          }
        });

        editorRef.current.on("component:resize", (model) => {
          if (model.component.attributes.type === "image") {
            dispatch({ type: 'EDITOR_CHANGE_STATUS', payload: true });
          }
        });

        editorRef.current.on("component:update", (model) => {
          if (model.attributes.type === "iframe" && model._changing ) {
            dispatch({ type: 'EDITOR_CHANGE_STATUS', payload: true });
          }
        });

        // Listen for changes to the image source
        editorRef.current.on("component:update:src", (model) => {
          const selectedComponent = window.editor.getSelected();
          if (selectedComponent.getId() === "site_logo") {
            const component =
              window.editor.DomComponents.getWrapper().find(`#footer_logo`)[0];
            if (component) {
              component.set("src", selectedComponent.getAttributes().src);
            }
          }

          if (selectedComponent.getId() === "footer_logo") {
            const component =
              window.editor.DomComponents.getWrapper().find(`#site_logo`)[0];
            if (component) {
              component.set("src", selectedComponent.getAttributes().src);
            }
          }

          const placeholderStatus = selectedComponent.attributes.src
            ? checkPlaceholderExists(selectedComponent.attributes.src)
            : false;
          setIsPlaceholderImage(placeholderStatus);
          addEditIcon(placeholderStatus);
          setSelectedImage(selectedComponent.attributes.src);

          // const htmlCode = editorRef.current.getHtml();
          // // Get the CSS code
          // const cssCode = editorRef.current.getCss();
          // const projectData = editorRef.current.getProjectData();
          // Log or use the HTML and CSS code as needed
          // faqUnChecked(window.editor);
               faqChecked(window.editor);
          dispatch({ type: 'EDITOR_CHANGE_STATUS', payload: true });
        });
        window.editor.UndoManager.clear();


        // rte toolbar
        // const rte = window.editor.RichTextEditor;

        // // An example with state
        // const isValidAnchor = (rte) => {
        //   // a utility function to help determine if the selected is a valid anchor node
        //   const anchor = rte.selection().anchorNode;
        //   const parentNode  = anchor && anchor.parentNode;
        //   const nextSibling = anchor && anchor.nextSibling;
        //   return (parentNode && parentNode.nodeName === 'A') || (nextSibling && nextSibling.nodeName === 'A')
        // }
        // rte.remove('link');
        // rte.remove('wrap');
        // rte.add('link', {
        //   icon: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"></path></svg>',
        //   attributes: { title: 'Link' },
        //   state: (rte, doc) => {
        //     if (rte && rte.selection()) {
        //       if (selectedTextCount) {
        //         rteData = rte;
        //       }
        //       // `btnState` is a integer, -1 for disabled, 0 for inactive, 1 for active
        //       return isValidAnchor(rte) ? 1 : 0;
        //     } else {
        //       return 0;
        //     }
        //   },
        //   result: async (rte, action) => {
        //     console.log(rteData,"rteData");
        //     // Save the selected content before the modal opens
        //     const selectedText = rte.selection() ? rte.selection().toString() : '';
        //     console.log(selectedTextCount,"selectedTextCount",selectedText);
        //     if (selectedTextCount) {
        //       console.log(selectedTextCount,"selectedTextCount",selectedText);
        //       selectedTextData = selectedText;
        //     }
        //     selectedTextCount = false;

        //     console.log('selectedText', selectedTextData);

        //     console.log(selectedTextCount,"selectedTextCount");
          
        //     if (isValidAnchor(rte)) {
        //       rte.exec('unlink');
        //     } else {
        //       Swal.fire({
        //         title: '<span class="text-black">Enter URL</span>',
        //         input: 'url',
        //         inputAttributes: {
        //           autocapitalize: 'off'
        //         },
        //         inputPlaceholder: 'Enter URL',
        //         showCancelButton: true,
        //         confirmButtonText: 'Insert',
        //         showLoaderOnConfirm: true,
        //         showCloseButton: true,
        //         customClass: {
        //           confirmButton: 'swal2-confirm custom-confirm-button',
        //           cancelButton: 'swal2-cancel custom-cancel-button',
        //           input: 'swal2-input scrapper-form-text rounded-[50px] px-6 py-[20px] border-2 border-black',
        //           closeButton: "close-button"
        //         },
        //         preConfirm: (value) => {
        //           if (!value) {
        //             Swal.showValidationMessage('URL is required');
        //           }
        //           return value;
        //         },
        //         allowOutsideClick: () => !Swal.isLoading()
        //       }).then((result) => {
        //         if (result.isConfirmed) {
        //           const value = result.value;
        //           const selectedComponent = window.editor.getSelected();
        //           if (selectedComponent) {
        //             selectedComponent.trigger('focus');
        //           }

        //           console.log('selectedText', selectedTextData, rteData);
        //           // Insert the hyperlink with the saved selected text
        //           if (selectedTextData) {
        //             rteData.insertHTML(`<a class="link" target="_blank" href="${value}">${selectedTextData}</a>`);
        //             selectedTextData = "";
        //             selectedTextCount = true;
        //             rteData = "";

        //           } else {
        //             toast.error('Select text to add hyperlink'); 
        //             // rte.insertHTML(`<a class="link" target="_blank" href="${value}">${value}</a>`);
        //           }
        //           dispatch({ type: 'EDITOR_CHANGE_STATUS', payload: true });
        //         }
        //       });
        //     }
        //   }
        // })

        // rte toolbar
        const rte = window.editor.RichTextEditor;

        // Utility function to check if selection is valid for link
        const isValidAnchor = (rte) => {
          const anchor = rte.selection().anchorNode;
          const parentNode = anchor && anchor.parentNode;
          const nextSibling = anchor && anchor.nextSibling;
          return (parentNode && parentNode.nodeName === 'A') || (nextSibling && nextSibling.nodeName === 'A')
        };
        var range = null;
        rte.remove('link');
        rte.remove('wrap');
        rte.add('link', {
          icon: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"></path></svg>',
          attributes: { title: 'Link' },
          state: (rte, doc) => {
            return isValidAnchor(rte) ? 1 : 0;
          },
          result: async (rte, action) => {
            // Save the selected content before the modal opens
            const selectedText = rte.selection() ? rte.selection().toString() : '';
          
            if (isValidAnchor(rte)) {
              rte.exec('unlink');
            } else {
              Swal.fire({
                title: '<span class="text-black">Enter URL</span>',
                input: 'url',
                inputAttributes: {
                  autocapitalize: 'off'
                },
                inputPlaceholder: 'Enter URL',
                showCancelButton: true,
                confirmButtonText: 'Insert',
                showLoaderOnConfirm: true,
                showCloseButton: true,
                customClass: {
                  confirmButton: 'swal2-confirm custom-confirm-button',
                  cancelButton: 'swal2-cancel custom-cancel-button',
                  input: 'swal2-input scrapper-form-text rounded-[50px] px-6 py-[20px] border-2 border-black',
                  closeButton: "close-button"
                },
                preConfirm: (value) => {
                  if (!value) {
                    Swal.showValidationMessage('URL is required');
                  }
                  return value;
                },
                allowOutsideClick: () => !Swal.isLoading()
              }).then((result) => {
                if (result.isConfirmed) {
                  const value = result.value;
                  const selectedComponent = window.editor.getSelected();
                  if (selectedComponent) {
                    selectedComponent.trigger('focus');
                  }
                  // Insert the hyperlink with the saved selected text
                  if (selectedText) {
                    rte.insertHTML(`<a class="link" target="_blank" href="${value}">${selectedText}</a>`);
                  } else {
                    toast.error('Select text to add hyperlink'); 
                    // rte.insertHTML(`<a class="link" target="_blank" href="${value}">${value}</a>`);
                  }
                  dispatch({ type: 'EDITOR_CHANGE_STATUS', payload: true });
                }
              });
            }
          }
         
        });
      })
      .catch((error) => {
        // Handle error as needed
        setWebsiteDeleted(true); 
      });
  };

  const addSectionIcon = () => {
    const selectedComponent = window.editor.getSelected();
    const customItem = {
      attributes: {},
      //class: 'class-one class-two class-three',
      command: "add-section", // Use a unique command name
      label: document.createElement("div"), // Create a container element
    };

    // Render the pencil icon into the container element
    customItem.label.innerHTML = ReactDOMServer.renderToString(
      <Icon path={mdiPlus} />
      // "Add Section"
    );


    // Add event listener to open modal when the pencil icon is clicked
    customItem.label.addEventListener("click", () => {
      // dispatch({ type: 'SECTION_ADD_STATUS', payload: true });
      setOpenAddSectionModal(true);

    });

    const defaultToolbar = selectedComponent.get("toolbar");
    // check if this command already exists on this component toolbar
    const commandExists = defaultToolbar.some(
      (item) => item.command === customItem.command
    );

    // if it doesn't already exist, add it
    if (
      !commandExists
      // && !command1Exists
    ) {
      selectedComponent.set({
        toolbar: [
          ...defaultToolbar,
           customItem ,
          // , customItem1
        ],
      });
    }
  };

  const addLinkIcon = () => {
    const selectedComponent = window.editor.getSelected();
    // Create custom item for linking
    const linkItem = {
      attributes: {},
      command: "add-link", // Use a unique command name
      label: document.createElement("div"), // Create a container element
    };

    linkItem.label.innerHTML = ReactDOMServer.renderToString(
      <Icon path={mdiLink} />
    );

    linkItem.label.addEventListener("click", () => {
      // Add logic to handle the link action
      setOpenButtonLinkModal(true);
    });

    const defaultToolbar = selectedComponent.get("toolbar");
    // check if this command already exists on this component toolbar
    const commandExists = defaultToolbar.some(
      (item) => item.command === linkItem.command
    );

    // if it doesn't already exist, add it
    if (!commandExists) {
      selectedComponent.set({
        toolbar: [...defaultToolbar, linkItem],
      });
    }
  };

  const addEditIcon = (placeholderStatus) => {
    const selectedComponent = window.editor.getSelected();
    const customItem = {
      attributes: {},
      command: "edit-image", // Use a unique command name
      label: document.createElement("div"), // Create a container element
    };
    // Render the pencil icon into the container element
    customItem.label.innerHTML = ReactDOMServer.renderToString(
      <Icon path={mdiPencil} />
    );

    // Add event listener to open modal when the pencil icon is clicked
    customItem.label.addEventListener("click", () => {
      if (selectedComponent.attributes.src) {
        const originalImage = removeCroppedFromImageName({
          imageUrl: selectedComponent.attributes.src,
        });
        return editImage(
          originalImage.modifiedUrl,
          selectedComponent.attributes.src
        );
      }
      editImage();
    });

    const defaultToolbar = selectedComponent.get("toolbar");
    // check if this command already exists on this component toolbar
    const commandExists = defaultToolbar.some(
      (item) => item.command === customItem.command
    );

    // if it doesn't already exist, add it
    if (!commandExists) {
      selectedComponent.set({
        toolbar: [...defaultToolbar, !placeholderStatus ? customItem : ""],
      });
    }
  };

  const editImage = (originalImageUrl, modifiedImageUrl) => {
    setOriginalImage(originalImageUrl);
    setSelectedImage(modifiedImageUrl);
    // setModalOpen(true);
    editorRef.current.runCommand("open-assets");
  };
  useEffect(() => {
    if (cropper) {
      cropper.destroy();
    }
    if (isModalOpen && selectedImage) {
      const imageElement = document.getElementById("cropper-image");
      imageElement?.classList.add("cropper-hidden");

      if (imageElement) {
        setCropperLoaded(false);
        const cropperInstance = new Cropper(imageElement, {
          dragMode: "move",
          movable: true,
          viewMode: 0,
          autoCropArea: 0.8,
          aspectRatio: cropperAspectRatio,
          crop: (event) => {
            // setCanvasBoxData(cropperInstance.getCanvasData());
            const croppedCanvas = cropperInstance.getCroppedCanvas();
            const croppedImageDataUrl = croppedCanvas.toDataURL();
            // cropperInstance.replace('')
            setCroppedImageDataUrl(croppedImageDataUrl);
            setAddBackground(false);
            setAddBackgroundImageUrl(null);
            // set image preview width in ratio height or width shuld be max 285px
            const previewImageWidth =
              croppedCanvas.width > croppedCanvas.height
                ? 285
                : (croppedCanvas.width * 285) / croppedCanvas.height;
            const previewImageHeight =
              croppedCanvas.height > croppedCanvas.width
                ? 210
                : (croppedCanvas.height * 285) / croppedCanvas.width;
            setPreviewImageStyle({
              width: `${previewImageWidth}px`,
              height: `${previewImageHeight}px`,
            });
          },
          
          ready: function () {
            const zoomedOutWidth = cropperWidth- ((cropperWidth*50)/100)
            const zoomedOutHeight = cropperHeight- ((cropperHeight*50)/100)
            cropperInstance.setCropBoxData({
              left: ((800-zoomedOutWidth)/2),
              top: ((400-zoomedOutHeight)/2),
              width: (zoomedOutWidth), // Set the width of the crop box by reducing it by 40% of its actual width.
              height: (zoomedOutHeight), // Set the height of the crop box by reducing it by 40% of its actual height.
            });
            cropperInstance.zoom(-1);
            setCropperLoaded(true);

            // Get the initial zoom ratio using canvas data
            const canvasData = cropperInstance.getCanvasData();
            const initialZoomRatioReady = Math.round((canvasData.width / canvasData.naturalWidth) * 10) / 10;
            setInitialZoomRatio(initialZoomRatioReady - 0.1);
          },
          zoom: function (event) {
            // 1 decimal floating point
            const zoomRatio = Math.round(event.detail.ratio * 10) / 10;
            setRangeval(zoomRatio);
          },
          // Add other CropperJS options here
        });
        setCropper(cropperInstance);
      }
    }
    if (!isModalOpen) {
      const selectedComponent = window.editor?.getSelected();
      selectedComponent
        ? setSelectedImage(selectedComponent.attributes.src)
        : setSelectedImage(selectedImage);
    }
  }, [isModalOpen, selectedImage]);

  const removeImageBackground = async () => {
    // Check if the Cropper instance exists and the canvas is valid
    if (cropper) {
      // Get the cropped canvas data
      const croppedCanvas = await cropper.getCroppedCanvas();
      // Get the data URL of the cropped image
      const croppedImageDataUrl = await croppedCanvas.toDataURL();
      setRemoveBackgroundLoader(true);
      dispatch(removeBackground(croppedImageDataUrl))
        .then(async (response) => {
          setRemoveBackgroundLoader(false);
          setAddBackground(true);
          setCroppedImageDataUrl(response?.data?.image);
          setSelectedImage(response?.data?.image);
          setAddBackgroundImageUrl(response?.data?.image);
        })
        .catch((error) => {
          console.error(error.message);
          setRemoveBackgroundLoader(false);
          // Handle error as needed
        });
    }
  };

  const getCroppedImage = async (dataURL) => {
    // Check if the Cropper instance exists and the canvas is valid
    if (cropper || dataURL) {
      setSavingImage(true);
      // Get the cropped canvas data
      // const croppedCanvas = await cropper.getCroppedCanvas();
      // Get the data URL of the cropped image
      // const croppedImageDataUrl = await croppedCanvas.toDataURL();

      if (croppedImageDataUrl || dataURL) {
        const extension = selectedImage.match(/\.(\w+)(\?|$)/)?selectedImage.match(/\.(\w+)(\?|$)/)[1]:'jpg';
        // const imageName = ImageNameExtractor({ imageUrl: selectedImage });
        // if (imageName.name.length > 100) {
        //   imageName.name = Date.now();
        // }
        const imageName = `${Date.now()}.${extension}`;
        const imageurl = croppedImageDataUrl ? croppedImageDataUrl : dataURL;
        dispatch(
          uploadImageToS3Bucket({
            file: imageurl,
            folder_name: id,
            file_name: imageName,
          })
        )
          .then(async (response) => {
            if (response?.data?.file) {
              const selectedImage = editorRef.current.getSelected();
              if(selectedImage.attributes.tagName ==='img'){
                editorRef.current.AssetManager.add(response?.data?.file);
                selectedImage.set("src", response?.data?.file);                
              }else{
                const src = response?.data?.file
                const uploadUrl = `<img src="${src}" alt=""/>`
                window.editor.runCommand('replace-video-with-img', { uploadUrl });
              }
              setSavingImage(false);
                setModalOpen(false);
                setCroppedImageDataUrl(null);
                setOpenAddBackgroundModal(false);
                setAddBackground(false);
                setAddBackgroundImageUrl(null);
            }
          })
          .catch((error) => {
            // Handle error as needed
            setSavingImage(false);
            console.log("Error in fetching s3 bucket images", error);
          });
      }
    }
  };

  const handleRangeChange = (event) => {
    const value = parseFloat(event.target.value);
    zoomTo(value);
  };

  const zoomTo = (value) => {
    if (cropper) {
      cropper.zoomTo(value);
    }
  };

  const rotateLeft = () => {
    if (cropper) {
      cropper.rotate(-45);
    }
  };

  const rotateRight = () => {
    if (cropper) {
      cropper.rotate(45);
    }
  };

  const handleScaleX = (X) => {
    if (cropper) {
      if (scaleX === 1) {
        setScaleX(X);
        cropper.scaleX(X);
      } else {
        setScaleX(1);
        cropper.scaleX(1);
      }
    }
  };

  const handleScaleY = (Y) => {
    if (cropper) {
      if (scaleY === 1) {
        setScaleY(Y);
        cropper.scaleY(Y);
      } else {
        setScaleY(1);
        cropper.scaleY(1);
      }
    }
  };

    // get s3 bucket images
    const getBucketImages = () => {
      setMoreImgLoading(true);
      dispatch(
        getSavedVariant({
          id: id,
          variantId: variantId,
        })
      )
        .then(async (response) => {
            setWebsiteDeleted(false); 
            setMoreImgLoading(false);
            if (response?.data?.assets_images) {
              setShowImageList(true);
              setAssets(response?.data?.assets_images);
            }
            // dispatch(
            //   getS3Images({
            //     bucket_name: process.env.REACT_APP_S3_BUCKET,
            //     prefix: `${response.data.folder_name}/images/assets_images/`,
            //   })
            // )
            //   .then(async (response) => {
            //     setMoreImgLoading(false);
            //     setShowImageList(true);
            //     setAssets(response.data)
            //   })
            //   .catch((error) => {
            //     // Handle error as needed
            //     console.log("Error in fetching s3 bucket images", error);
            //   });
        }).catch((error) => {
            // Handle error as needed
            setWebsiteDeleted(true); 
        });
    };

  const reset = () => {
    setRemoveBackgroundLoader(false);
    setAddBackground(false);
    setShowImageList(false);
    setMoreImgLoading(false);
    setSavingImage(false);
    setModalOpen(false);
    setCroppedImageDataUrl(null);
    setOriginalImage('');
  };

  const getNewImageProgress = async (messageId) => {
    try {
      let response;
      while (true) {
        response = await dispatch(getImageProgress(messageId));
        if (response?.data?.progress === 100 || response?.data?.status === "FAIL" || response?.data?.status === "DONE") {
          setMessageId(null);
          return response;
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
        setMessageId(response?.data);
      }
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  };


  useEffect(() => {    
    if(!messageId){
    getGeneratedData();
    }
  },[messageId]);
  const getGeneratedData = async () => {
      dispatch(getGeneratedImages(userId, id))
        .then((res) => {
          setAiImages(res.data);
        })
        .catch((error) => console.error(error));
    };

  return (
    !websiteDeleted ?
    (<div className="testNew h-screen">
      <GjsEditor
        className="gjs-custom-editor grapejs-menu gjs-two-color bg-white h-screen w-full"
        grapesjs="/js/grapes.min.js"
        grapesjsCss="/css/grapes.min.css"
        options={gjsOptions}
        onEditor={onEditor}
      >
        <div className={`flex h-full  mainDiv`}>
          <div className="gjs-column-m flex flex-col flex-grow overflow-hidden">
            <Topbar
              rightToggelValue={toggleRightSidebar}
              showSidebar={showSidebar}
              className="min-h-20"
              editorState={user.editor_change_status}
            />
            <Canvas className="flex-grow gjs-custom-editor-canvas h-full w-full" />
          </div>
          {showSidebar && (
            <RightSidebar
              className={`gjs-column-r w-[300px] border-l ${MAIN_BORDER_COLOR}`}
            />
          )}
        </div>

        <ModalProvider>
          {({ open, title, content, close }) => (
            <CustomModal
              open={open}
              title={title}
              children={content}
              close={close}
            />
          )}
        </ModalProvider>
        <AssetsProvider>
          {({ assets, select, close, Container }) => (
            <Container>
              <CustomAssetManager
                editorRef={editorRef}
                setSelectedImage={setSelectedImage}
                assets={assets}
                aiImages={aiImages}
                select={select}
                close={close}
                setModalOpen={setModalOpen}
                setIsCreateImageModalOpen={setIsCreateImageModalOpen}
                setIsUploadImageModalOpen={setIsUploadImageModalOpen}
                isPlaceholderImage={isPlaceholderImage}
                messageId = {messageId}
                setMessageId={setMessageId}
                setOriginalImage={setOriginalImage}
                setIsUploadVideoModalOpen={setIsUploadVideoModalOpen}
                setOpenEditImagePropModal={setOpenEditImagePropModal}
              />
            </Container>
          )}
        </AssetsProvider>
      </GjsEditor>
      {/* + */}

      {isModalOpen && !openAddBackgroundModal && (
        <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded relative w-[1200px]">
          <div className="flex   justify-between">
          <button className="flex items-center text-[14px] gap-1.5 mb-[15px]"
           onClick={() => {
            setTimeout(() => {
              reset()
              setOriginalImage('')
              editorRef.current.runCommand("open-assets");
            }, 500); // Wait for fade-out transition
          }}>
           <img
              src="/images/arrow.svg"
              alt="arrow"
              className="w-[14px]"
            />
            Back</button>
            <button
            type="button"
            onClick={reset}
          >
            <Icon size={1} path={mdiClose} />
          </button>
        </div>
            <div className="absolute flex justify-between w-[95%]">
              <h2>Edit Mask/Photo</h2>
            </div>
            <div className="flex mt-12">
              <div className="crop-section">
                <div class="cropper-container">
                  {!cropperLoaded && (
                    <div className="absolute top-[50%] left-[50%] z-10 w-[60px] h-[60px]">
                      <PageLoaderJoxy />
                    </div>
                  )}
                  <img
                    id="cropper-image"
                    src={selectedImage}
                    alt="SelectedImage"
                  />
                </div>
                <div className=" flex justify-between items-center mt-5">
                  <div className=" ">
                    <div className=" flex items-center control">
                      <span className="me-6">
                        <img src="/images/zoom-out.svg" alt="zoom-out" />
                      </span>
                      <input
                        type="range"
                        className="custom-range"
                        min={initialZoomRatio}
                        max="10"
                        step="0.1"
                        value={rangeval}
                        onChange={handleRangeChange}
                      />
                      <span className="ms-6">
                        <img src="/images/zoom-in.svg" alt="zoom-in" />
                      </span>
                    </div>
                  </div>
                  <div className=" ">
                    <button
                      type="button"
                      className="rounded-lg bg-[#2688D1] mr-2  p-2.5 text-center text-sm font-medium text-white"
                      disabled={savingImage}
                      onClick={() => rotateLeft()}
                    >
                      <Icon size={0.7} path={mdiRotateLeft} />{" "}
                    </button>

                    <button
                      type="button"
                      className="rounded-lg bg-[#2688D1] mr-2 p-2.5 text-center text-sm font-medium text-white"
                      disabled={savingImage}
                      onClick={() => rotateRight()}
                    >
                      <Icon size={0.7} path={mdiRotateRight} />{" "}
                    </button>

                    <button
                      type="button"
                      className="rounded-lg bg-[#2688D1] mr-2  p-2.5 text-center text-sm font-medium text-white"
                      disabled={savingImage}
                      onClick={() => handleScaleY(-1)}
                    >
                      <Icon
                        className="rotate-[136deg]"
                        size={0.7}
                        path={mdiArrowExpand}
                      />{" "}
                    </button>

                    <button
                      type="button"
                      className="rounded-lg bg-[#2688D1] p-2.5 text-center text-sm font-medium text-white"
                      disabled={savingImage}
                      onClick={() => handleScaleX(-1)}
                    >
                      <Icon
                        size={0.7}
                        className="rotate-[46deg]"
                        path={mdiArrowExpand}
                      />{" "}
                    </button>
                  </div>
                </div>
              </div>

              {croppedImageDataUrl && !showImageList && (
                <div className="flex flex-col justify-between text-center  ml-2.5">
                  <div className="overflow-x-scroll">
                    <div className="preview-image-container">
                    <img
                      className="border-black border-solid border custom-rounded cropped_preview object-cover"
                      src={croppedImageDataUrl}
                      style={previewImageStyle}
                      alt="Cropped"
                    />
                     <div className="gridlines-3x3">
                      <div></div><div></div><div></div>
                      <div></div><div></div><div></div>
                      <div></div><div></div><div></div>
                    </div>
                    <div className="gridlines-2x2">
                      <div></div><div></div>
                      <div></div><div></div>
                    </div>
                    </div>
                    {/* {
                    !addBackground ? (
                      <> */}
                    {removeBackgroundLoader ? (
                      <button
                        type="button"
                        className="rounded-lg bg-black mt-3 px-5 py-2.5 text-center text-sm font-medium text-white w-60"
                        disabled
                      >
                        <ButtonLoaderJoxy />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="rounded-lg bg-black mt-3 px-5 py-2.5 text-center text-sm font-medium text-white w-60"
                        onClick={removeImageBackground}
                        disabled={savingImage}
                      >
                        Remove Background
                      </button>
                    )}
                    {/* </>
                    ):( */}
                    <button
                      type="button"
                      className="rounded-lg bg-black mt-3 px-5 py-2.5 text-center text-sm font-medium text-white w-60"
                      onClick={() => {
                        setModalOpen(false);
                        setCroppedImageDataUrl(null);
                        setOpenAddBackgroundModal(true);
                        setAddBackgroundImageUrl(croppedImageDataUrl);
                      }}
                      disabled={savingImage}
                    >
                      Add AI Background
                    </button>
                    {/* )
                  } */}

                    {originalImage !== selectedImage && originalImage && (
                      <button
                        type="button"
                        className="rounded-lg bg-black mt-3 px-5 py-2.5 text-center text-sm font-medium text-white w-60"
                        onClick={() => {
                          setSelectedImage(originalImage);
                        }}
                        disabled={savingImage}
                      >
                        Reset To Original
                      </button>
                    )}
                    {!moreImgLoading ? (
                      <button
                        type="button"
                        className="rounded-lg bg-black mt-3 px-5 py-2.5 text-center text-sm font-medium text-white w-60"
                        onClick={getBucketImages}
                        disabled={savingImage}
                      >
                        All Saved Images
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="rounded-lg bg-black mt-3 px-5 py-2.5 text-center text-sm font-medium text-white w-60"
                        disabled
                      >
                        <ButtonLoaderJoxy />
                      </button>
                    )}
                  </div>
                  <div className="mt-3">
                    {!savingImage ? (
                      <button
                        type="button"
                        className=" w-60 rounded-lg bg-[#2688D1] px-5 py-2.5 text-center text-sm font-medium text-white"
                        onClick={getCroppedImage}
                      >
                        Use Image
                      </button>
                    ) : (
                      <button
                        type="button"
                        className=" w-60 rounded-lg bg-[#2688D1] px-5 py-2.5 text-center text-sm font-medium text-white"
                        disabled
                      >
                        <ButtonLoaderJoxy />
                      </button>
                    )}
                  </div>
                </div>
              )}

              {showImageList && (
                <div className="h-[506px]">
                  <div className={`${expandedSection==='saved'?'h-[89%]':''}`}>
                  <div className="ms-3 flex items-center justify-between cursor-pointer" onClick={()=>{
                      expandedSection==='ai_generated'?setExpandedSection('saved'):setExpandedSection('ai_generated')
                    }}>
                    <div className="flex items-center ms-3">
                      <span
                        className=" flex justify-center items-center cursor-pointer"
                        // bg-[#F1F4F9]
                        onClick={() => {
                          setShowImageList(false);
                        }}
                      >
                        <img
                          src="/images/arrow.svg"
                          alt="arrow"
                        />
                      </span>
                      <span className="ms-3 font-bold text-sm">
                        Saved Images
                      </span>
                    </div>
                    {expandedSection==='saved'?<span onClick={()=>setExpandedSection('ai_generated')}><ExpandLessOutlined /></span>:
                    <span onClick={()=>setExpandedSection('saved')}><ExpandMoreOutlined /></span>}
                    </div>
                    {expandedSection==='saved' && <div className=" max-h-[90%] grid grid-cols-3 ms-3 mt-3 gap-2 h-[464px] overflow-y-auto">
                      {assets.map((img,index) => (
                      <div
                      key={index}
                      className="image-box w-[76px] h-[76px] rounded logo-trans"
                    >
                    <img className="image" src={img} alt="selected" />
                    <div className="overlay w-[76px] h-[76px] rounded">
                      <button
                        className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                        onClick={() => {
                          setSelectedImage(img);
                          setShowImageList(false);
                        }}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                      ))}
                    </div>}
                  </div>
                  {aiImages.length>0 &&
                  <div className={`${expandedSection==='ai_generated'?'h-[86%] mt-4':'mt-4'}`}>
                    <div className="ms-3 grid">
                    <div className="flex items-center justify-between cursor-pointer" onClick={()=>{
                      expandedSection==='ai_generated'?setExpandedSection('saved'):setExpandedSection('ai_generated')
                    }}>
                        <span className="font-bold text-sm">AI Generated Images</span>
                        {expandedSection==='ai_generated'?<span onClick={()=>setExpandedSection('saved')}><ExpandLessOutlined /></span>:
                        <span onClick={()=>setExpandedSection('ai_generated')}><ExpandMoreOutlined /></span>}
                        </div>
                        <span className="text-[10px]">(To modify a previously generated image click edit)</span>
                    </div>
                    {expandedSection==='ai_generated'&&<div className="max-h-[95%] grid-container grid grid-cols-3 ms-3 mt-3 gap-1.5 overflow-y-auto">
                    {aiImages.map((img, index) => (
                          <div
                            key={index}
                            className="image-box w-[76px] h-[76px] rounded logo-trans"
                          >
                            <img className="image" src={img.thumbnailUri} alt="background" />
                            <div className="overlay w-[76px] h-[76px] rounded">
                              <button
                                className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                                onClick={() => {
                                  setSelectedImage(img.imageUri);
                                  setShowImageList(false);
                                }}
                                disabled={savingImage}
                              >
                                Select
                              </button>
                              <button
                                className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                                onClick={() => {
                                  setMessageId(img);
                                  setIsCreateImageModalOpen(true)
                                  setModalOpen(false);
                                  setShowImageList(false);
                                }}
                                disabled={savingImage}
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>}
                  </div>
                      }
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {openAddBackgroundModal && (
        <CustomAddBackgroundModal
          addBackgroundImageUrl={addBackgroundImageUrl}
          setOpenAddBackgroundModal={setOpenAddBackgroundModal}
          getCroppedImage={getCroppedImage}
          setCroppedImageDataUrl={setCroppedImageDataUrl}
          cropperWidth={cropperWidth}
          cropperHeight={cropperHeight}
          setSelectedImage={setSelectedImage}
          setModalOpen={setModalOpen}
          setOriginalImage={setOriginalImage}
        />
      )}
      {isCreateImageModalOpen && (
        <CustomGenerateImageModal
          setIsCreateImageModalOpen={setIsCreateImageModalOpen}
          setOpenAddBackgroundModal={setOpenAddBackgroundModal}
          editorRef={editorRef}
          setAddBackgroundImageUrl={setAddBackgroundImageUrl}
          setSelectedImage={setSelectedImage}
          setModalOpen={setModalOpen}
          getNewImageProgress={getNewImageProgress}
          messageId={messageId}
          setOriginalImage={setOriginalImage}
        />
      )}
      {isUploadImageModalOpen && (
        <CustomUploadImageModal
          setIsUploadImageModalOpen={setIsUploadImageModalOpen}
          editorRef={editorRef}
          setSelectedImage={setSelectedImage}
          setModalOpen={setModalOpen}
          setMessageId = {setMessageId}
          setIsCreateImageModalOpen = {setIsCreateImageModalOpen}
          setOriginalImage={setOriginalImage}
          aiImages={aiImages}
        />
      )}
      {isUploadVideoModalOpen && (
        <CustomUploadVideoModal
          setIsUploadVideoModalOpen={setIsUploadVideoModalOpen}
          editorRef={editorRef}
          setSelectedImage={setSelectedImage}
          setModalOpen={setModalOpen}
          setMessageId = {setMessageId}
          setIsCreateImageModalOpen = {setIsCreateImageModalOpen}
          setOriginalImage={setOriginalImage}
          aiImages={aiImages}
        />
      )}
      {isUploadVideoModalOpen && (
        <CustomUploadVideoModal
          setIsUploadVideoModalOpen={setIsUploadVideoModalOpen}
          editorRef={editorRef}
          setSelectedImage={setSelectedImage}
          setModalOpen={setModalOpen}
          setMessageId = {setMessageId}
          setIsCreateImageModalOpen = {setIsCreateImageModalOpen}
          setOriginalImage={setOriginalImage}
          aiImages={aiImages}
        />
      )}
      {openEditImagePropModal && (
        <CustomEditImagePropModal
          setOpenEditImagePropModal={setOpenEditImagePropModal}
          editorRef={editorRef}
          setSelectedImage={setSelectedImage}
          setModalOpen={setModalOpen}
          setMessageId = {setMessageId}
          setIsCreateImageModalOpen = {setIsCreateImageModalOpen}
          setOriginalImage={setOriginalImage}
          aiImages={aiImages}
        />
      )}
      {
        openButtonLinkModal &&(
          <CustomButtonLinkModal
            setOpenButtonLinkModal={setOpenButtonLinkModal}
            editorRef={editorRef}
          />
        )
      }
      {
        openAddSectionModal &&(
          <CustomAddSectionModal
            setOpenAddSectionModal={setOpenAddSectionModal}
          />
        )
      }
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    </div>) :
    (<div className="text-center mt-[50vh]">
    <div className="text-[32px] font-bold leading-[40px] bg-[linear-gradient(135deg,_#02BFD5_0%,_#2688D1_29.07%,_#F92672_100%)] bg-clip-text text-transparent">Website Got Deleted</div>
  </div>)

  );
}
