import React, { useState, useRef, useEffect } from "react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { websiteTransfer } from "../../actions/grapesjs";
import { getUsersEmail } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ButtonLoaderJoxy } from "./LoaderJoxy";

const CustomWebsiteTransferModal = ({
  setWebsiteTransferModal,
  websiteId,
  ...props
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]); // Original email list
  const inputRef = useRef();

  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const token = localStorage.getItem("user");
  const variantId = getQueryParam("v_id");
  const userData = JSON.parse(token);
  const userId = userData.user.id;

  useEffect(() => {
    const fetchUserEmails = async () => {
      try {
        const userEmails = await dispatch(getUsersEmail({ email: userData.user.email }));
        console.log("User emails:", userEmails.data);
        setAllOptions(userEmails.data || []); 
        setOptions((userEmails.data || []).slice(0, 5)); // Store the original list
      } catch (error) {
        console.error("Error fetching user emails:", error);
      }
    };

    fetchUserEmails();
  }, [dispatch, userData.user.email]);

  const handleInputChange = ({ target }) => {
    setEmail(target.value);
    setError("");

    if (target.value) {
      const filteredOptions = allOptions.filter((option) =>
        option.toLowerCase().startsWith(target.value.toLowerCase())
      ).slice(0, 5);
      setOptions(filteredOptions);
    } else {
      setOptions(allOptions.slice(0, 5)); // Reset options if input is cleared
    }
  };

  const TransferWebsite = () => {
    if (!email) {
      return setEmailError("Email is required");
    }
    const data = {
      userId: userId,
      websiteId: id || websiteId,
      email: email,
    };
    setLoader(true);
    dispatch(websiteTransfer(data))
      .then((response) => {
        if (response.status !== 200) {
          setError(response.data.message);
        } else {
          setError("");
          setWebsiteTransferModal(false);
          Swal.fire({
            title: "Data Transfer",
            imageUrl: "/images/publish.svg",
            imageWidth: 166,
            imageHeight: 162.52,
            imageAlt: "Custom image",
            customClass: {
              htmlContainer: "htmlContainer1",
              title: "custom-swal-title",
              confirmButton: "btn-gotit",
              closeButton: "close-button",
              popup: "popup",
              image: "customimg",
            },
            html: `<div style="margin-bottom:24px;"><span style="font-size:14px; font-weight:400;">Ownership of this landing page has been successfully transferred to user ${email}</span></div>`,
            confirmButtonText: "Got it",
            showCloseButton: true,
          });
          setEmail("");
        }
      })
      .catch((error) => {
        setLoader(false);
        setError("User not found");
        console.error("Error in transferring website:", error);
      });
  };

  const validateEmail = (e) => {
    const email = e.target.value;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!email) {
      setEmailError("Email is required");
    } else if (!regex.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  return (
    <div className="edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[white] rounded-xl relative w-[484px]">
        <div className="flex justify-end pe-2 pt-2">
          <button
            className="outline outline-black outline-1 rounded-full"
            type="button"
            onClick={() => {
              setWebsiteTransferModal(false);
              setEmail("");
            }}
          >
            <Icon size={0.9} path={mdiClose} />
          </button>
        </div>
        <div className="flex justify-center">
          <span className="text-[20px] text-black font-bold leading-6">
            Transfer Website
          </span>
        </div>
        <div className="px-5 py-6">
          <div>
            <span className="font-medium text-[15px] text-black leading-5">
              User Email
            </span>
            <input
              ref={inputRef}
              type="text"
              list="userEmails"
              name="email"
              id="emailInput"
              autoComplete="off"
              onChange={(e) => {
                validateEmail(e);
                handleInputChange(e);
              }}
              className="w-[100%] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
              placeholder="Enter User Email"
            />
            <datalist id="userEmails">
              {options.map((item, index) => (
                <option key={index} value={item}>{item}</option>
              ))}
            </datalist>
          </div>
          {error && (
            <div className="mt-1 mb-2">
              <span className="font-normal text-[14px] text-[red] leading-5">
                {error}
              </span>
            </div>
          )}
          {emailError && !error && (
            <div className="mt-1 mb-2">
              <span className="font-normal text-[14px] text-[red] leading-5">
                {emailError}
              </span>
            </div>
          )}

          {loader &&  (
            <div className="pt-2.5">Please be patient, all assets, media, and variants of this landing page are being moved to the new user {email}</div>
          )}
          <div className="text-center mt-10">
            <button
              type="button"
              className="rounded text-[12px] outline outline-1 outline-black text-center font-medium w-[79px] h-[27px] text-black btn-bg hover:outline-none hover:text-white"
              onClick={() => {
                setEmail("");
                setWebsiteTransferModal(false);
              }}
            >
              Cancel
            </button>
            {emailError ? (
            <button
              type="button"
              className="ms-2 btn-bg-color outline outline-1 outline-black rounded leading-[15.08px] text-[12px] text-center h-[27px] font-medium px-2 text-black btn-bg hover:outline-none hover:text-white opacity-75"
              disabled
            >
              Transfer
            </button>
            ) : loader ? (
              <button
                type="button"
                className="ms-4 rounded-lg text-[12px] bg-[#2688D1] text-center font-medium text-white w-[70px] h-[32px]"
                disabled
              >
                <ButtonLoaderJoxy />
              </button>
            ) : (
              <button
                type="button"
                className="ms-2 btn-bg-color outline outline-1 outline-black rounded leading-[15.08px] text-[12px] text-center h-[27px] font-medium px-2 text-black btn-bg hover:outline-none hover:text-white"
                onClick={TransferWebsite}
              >
                Transfer
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomWebsiteTransferModal;
