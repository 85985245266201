import React, { useEffect, useState } from "react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { addDomain, getExistingDomains } from "../../actions/grapesjs";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const CustomAddDomainModal = ({
  setIsModalOpen,
  publish_url,
  websiteId,
  variant_Id,
  setUseDomainModal,
  ...props
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const [url, setUrl] = useState("");
  const [error, setError] = useState(null);
  const [pagePath, setPagePath] = useState("");
  const [existingDomains, setExistingDomains] = useState([]);

  // Function to extract query parameter from the URL
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const token = localStorage.getItem("user");
  // Extract the v_id from the query string
  const variantId = getQueryParam("v_id");

  // Parse the JSON string to get the object
  const userData = JSON.parse(token);
  // Access the "id" property from the user object
  const userId = userData.user.id;

  useEffect(() => {
    getExistingDomainsData();
  }, []);

  const getExistingDomainsData = async () => {
    dispatch(getExistingDomains(userId))
      .then(async (response) => {
        setExistingDomains(response.data);
      })
      .catch((error) => {
        // Handle error as needed
        console.log("Error in adding domain", error);
      });
  }

  const addCustomDomain = () => {
    if (!url) {
      return setError("Input Domain Name");
    }
    if(!publish_url){
      return toast.error("You haven't published this page yet");
    }
    const data = {
      userId: userId,
      websiteId: id ? id : websiteId,
      variantId: variantId ? variantId : variant_Id,
      domain: url,
      publish_url: publish_url,
      page_path: pagePath
    };
    dispatch(addDomain(data))
      .then(async (response) => {
        console.log({ response });
        if (response.data.message) {
          return setError(response.data.message);
        }
        setError("");
        setIsModalOpen(false);
        setUrl("");
        Swal.fire({
          title: "Configuring",
          imageUrl: "/images/publish.svg",
          imageWidth: 85,
          imageAlt: "Custom image",
          customClass: {
            title: "custom-swal-title-configuring",
            confirmButton: "btn-gotit",
            closeButton: "close-button",
            popup: "popup1",
            image: "customimg",
          },
          html: `<div style="margin-bottom:12px; padding:0 24px;">
                    <span style="font-size:14px; font-weight:400;">
                        We are configuring your domain. In most cases, this takes just a few minutes, but it can also take up to 24 hours. </span>
                </div>
                <h1 style="color:black; margin-bottom:8px;">Connect Your Domain to Joxy</h1>
                <div style="margin-bottom:12px;">
                    <ul style="list-style: disc;color: #000000c2; padding:revert; margin-bottom: 12px;">
                        <li style="font-size:12px;line-height:18px; font-weight:400; text-align:start;margin-bottom: 4px;">Log in to your domain provider and go to DNS settings </li>
                    </ul>
                    <div style=" display: flex;cursor:pointer;" id="cname-text"><span style="font-size:12px;font-weight:400;white-space: nowrap;margin-right:8px;">CNAME:</span>
                        <p style="font-size:12px;font-weight:400;"> ${(publish_url).replace("http://", "")}</p><span>📋</span>
                    </div>
                </div>
                <div>
                    <p style="margin-bottom:12px;font-size: 14px; color:black;">Follow the following steps to setup the DNS</p>
                    <ul style="list-style: disc;color: #000000c2; padding:revert; margin-bottom: 12px;">
                        <li style="font-size:12px;line-height:18px; font-weight:400; text-align:start;margin-bottom: 4px;">Log in to your DNS provider's admin page</li>
                        <li style="font-size:12px;line-height:18px;font-weight:400;text-align:start;margin-bottom: 4px;">Navigate to the DNS management page for your domain</li>
                        <li style="font-size:12px;line-height:18px;font-weight:400;text-align:start;margin-bottom: 4px;">Add a new record of type "CNAME"</li>
                        <li style="font-size:12px;line-height:18px;font-weight:400;text-align:start;margin-bottom: 4px;">In the "host", "name", or "subdomain" field, copy and paste the alphanumeric string from the email</li>
                        <li style="font-size:12px;line-height:18px;font-weight:400;text-align:start;margin-bottom: 4px;">In the "Target", "points to", or "value" field, copy and paste the alphanumeric string from the email</li>
                        <li style="font-size:12px;line-height:18px;font-weight:400;text-align:start;margin-bottom: 4px;">Click the TTL dropdown box, select Custom, and enter the value as 600</li>
                        <li style="font-size:12px;line-height:18px;font-weight:400;text-align:start;margin-bottom: 4px;">Click Save</li>
                    </ul>
                </div>`,
          showCancelButton: false,
          confirmButtonText: "Got it",
          showCloseButton: true,
        });
        // Add this script after the Swal call to handle CNAME copy to clipboard
        document
        .getElementById("cname-text")
        .addEventListener("click", () => {
          const cnameText = (publish_url).replace(
            "http://",
            ""
          );
          navigator.clipboard
            .writeText(cnameText)
            .then(() => {
              console.log("CNAME copied to clipboard:", cnameText);
            })
            .catch((err) => {
              console.error("Failed to copy CNAME: ", err);
            });
        });
      })
      .catch((error) => {
        // Handle error as needed
        console.error("Error in adding domain", error);
      });
  };

  return (
    <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
      <div className="bg-[white] rounded-xl relative w-[484px] ">
        <div className="flex justify-end pe-2 pt-2">
          <button
            className="outline outline-black outline-1 rounded-full"
            type="button"
            onClick={() => {
              setIsModalOpen(false);
              setUrl("");
            }}
          >
            <Icon size={0.9} path={mdiClose} color={'black'}/>
          </button>
        </div>
        <div className="flex justify-center">
          <span className="text-[20px] text-black font-bold leading-6">
            Add a New Domain
          </span>
        </div>
        <div className="px-5 pt-6">
          <div className="">
            <span className="font-medium text-[15px] text-black leading-5">
              What is the domain you would like to use?
            </span>
            <input
              type="text"
              onChange={(e) => {
                setUrl(e.target.value);
                setError(null);
              }}
              className=" w-[100%] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
              name="url"
              placeholder="try.example.com (without http:// or https://)"
              value={url}
            />
          </div>

          {error && (
            <div className="mt-1 mb-2">
              <span className="font-normal text-[14px] text-[red] leading-5">
                {error}
              </span>
            </div>
          )}
          <div className="mt-4">
            <span className="font-normal text-[14px] text-black leading-5">
              We recommend using a sub-domain like "try" or "get," but if you
              don’t have an existing website on that domain, you can also use
              "www."
            </span>
          </div>
          <div className="mt-3 mb-8">
            <span className="font-medium text-[15px] text-black leading-5">
              Page path
            </span>
            <input
              type="text"
              onChange={(e) => {
                 // Replace spaces with hyphens and update the state
                 setPagePath(e.target.value.replace(/\s+/g, '-'));
              }}
              className=" w-[100%] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
              name="pagePath"
              placeholder="abc-ab-abc"
              value={pagePath}
              autoComplete="off"
            />
          </div>

          <div className="text-center mb-10">
            <button
              type="button"
              className="rounded text-[12px] outline outline-1 outline-black text-center font-medium w-[79px] h-[27px] text-black btn-bg hover:outline-none hover:text-white"
              onClick={() => {
                setUrl("");
                setIsModalOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="ms-2 outline outline-1 outline-black rounded leading-[15.08px] text-[12px] text-center h-[27px] font-medium px-2 text-black btn-bg hover:outline-none hover:text-white"
              onClick={() => {
                addCustomDomain();
              }}
            >
              Connect Domain
            </button>
            {existingDomains.length>0 &&<button
              type="button"
              className="ms-2 outline outline-1 outline-black rounded leading-[15.08px] text-[12px] text-center h-[27px] font-medium px-2 text-black btn-bg hover:outline-none hover:text-white"
              onClick={() => {
                setUseDomainModal(true);
                setUrl("");
                setIsModalOpen(false);
              }}
            >
              Use My Existing Connected Domain
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAddDomainModal;
