import React, { useEffect, useState } from "react";
import { mdiClose, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { replaceImagesWithThumbnails } from "../constants/jsonData";
import { getSavedVariant } from "../../actions/grapesjs";
import { ButtonLoaderJoxy } from "./LoaderJoxy";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";

const CustomEditImagePropModal = ({
  editorRef,
  setSelectedImage,
  setModalOpen,
  setOpenEditImagePropModal,
  setMessageId,
  setIsCreateImageModalOpen,
  setOriginalImage,
  aiImages,
  ...props
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const [assets, setAssets] = useState([]);
  const [moreImgLoading, setMoreImgLoading] = useState(false);
  const [expandedSection, setExpandedSection] = useState("saved");
  const [rows, setRows] = useState([
    { attribute_name: "", attribute_value: "" },
  ]);
  const [title, setTitle] = useState("");
  const [alt, setAlt] = useState("");
  const [defaultAttributes, setDefaultAttributes] = useState(null);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([...rows, { attribute_name: "", attribute_value: "" }]);
  };

  const removeRow = (index) => {
    if (rows.length === 1) {
      return;
    }
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  // Function to extract query parameter from the URL
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  // Extract the v_id from the query string
  const variantId = getQueryParam("v_id");

  useEffect(() => {
    getBucketImages();

    const selectedComponent = window.editor.getSelected();
    const attributes = selectedComponent.getAttributes();
    if (attributes.title) {
      setTitle(attributes.title);
    }
    if (attributes.alt) {
      setAlt(attributes.alt);
    }
    const nonWriteableAttributes = {
      id: attributes.id,
      class: attributes.class,
      src: attributes.src,
    };
    setDefaultAttributes(nonWriteableAttributes);
    delete attributes.title;
    delete attributes.alt;
    delete attributes.id;
    delete attributes.class;
    delete attributes.src;

    const attributesArray = Object.entries(attributes).map(([key, value]) => ({
      attribute_name: key,
      attribute_value: value,
    }));

    // Update state with the new array
    if (attributesArray.length > 0) {
      setRows(attributesArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get s3 bucket images
  const getBucketImages = () => {
    setMoreImgLoading(true);
    dispatch(
      getSavedVariant({
        id: id,
        variantId: variantId,
      })
    )
      .then(async (response) => {
        setMoreImgLoading(true);
        if (response.data.assets_images) {
          setAssets(response.data.assets_images);
        }
      })
      .catch((error) => {
        // Handle error as needed
        console.log("Error in fetching template", error);
      });
  };

  const save = async () => {
    var error = false;
    if (!alt) {
      toast.error('Alt text can not be empty');
      return;
    }
    const other_attributes = rows.filter((row) => {
      // Check for empty attribute name or value
      if (
        row.attribute_name.trim() === "" ||
        row.attribute_value.trim() === ""
      ) {
        return false;
      }

      // Check if attribute name is "id" or "class"
      if (
        row.attribute_name.toLowerCase() === "id" ||
        row.attribute_name.toLowerCase() === "class"
      ) {
        error = true;
        toast.error('You cannot change "id" or "class".');
        return false;
      }

      // Return true if both checks are passed
      return true;
    });
    if(error){
      return;
    }
    const selectedComponent = window.editor.getSelected();
    const object = other_attributes.reduce((acc, curr) => {
      acc[curr.attribute_name] = curr.attribute_value;
      return acc;
    }, {});
    object.title = title;
    object.alt = alt;
    const combinedObj = Object.assign(object, defaultAttributes);

    selectedComponent.setAttributes(combinedObj);
    dispatch({ type: "EDITOR_CHANGE_STATUS", payload: true });
    setOpenEditImagePropModal(false);
  };

  return (
    <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white px-7 py-10 rounded relative w-[1200px]">
        <div className="flex   justify-between">
          <button
            className="flex items-center text-[14px] gap-1.5 mb-[15px]"
            onClick={() => {
              setTimeout(() => {
                editorRef.current.runCommand("open-assets");
                setOpenEditImagePropModal(false);
              }, 500); // Wait for fade-out transition
            }}
          >
            <img src="/images/arrow.svg" alt="arrow" className="w-[14px]" />
            Back
          </button>
          <button
            type="button"
            onClick={() => {
              setOpenEditImagePropModal(false);
            }}
          >
            <Icon size={1} path={mdiClose} />
          </button>
        </div>
        <div className="flex">
          <h2>Edit Image Properties</h2>
        </div>
        <div className="flex mt-10 w-[1140px] h-[446px]">
          <div className="outline outline-1 outline-[#959595] rounded-xl overflow-y-auto w-full h-[100%] px-10 pt-8">
            <div className="flex gap-4">
              <div>
                <span className="font-bold text-[15px] leading-[18.85px] text-black">
                  Title
                </span>
                <div>
                  <input
                    type="text"
                    className="w-[354px] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
                    placeholder="Image Title"
                    autoComplete="off"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                <span className="font-bold text-[15px] leading-[18.85px] text-black">
                  Alt
                </span>
                <div>
                  <input
                    type="text"
                    className="w-[354px] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
                    placeholder="Alt Text"
                    autoComplete="off"
                    value={alt}
                    onChange={(e) => {
                      setAlt(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="pb-2  mt-5">
              <span className="flex text-black font-bold text-[15px] items-center">
                Other Attributes
                <button
                  className="w-[20px] h-[20px] ms-2 rounded-full bg-[#2688D1] text-white flex items-center justify-center text-[12px] leading-[20.11px]"
                  data-twe-toggle="tooltip"
                  data-twe-placement="right"
                  data-twe-ripple-init
                  data-twe-ripple-color="light"
                  title='<img src="https://demo/url/gors/here" alt="example image"> 
                  here "alt" is attribute name and "example image" is the attribue value'
                >
                  ?
                </button>
              </span>
              <div className="h-[200px] overflow-auto ps-1 ">
                {rows.map((row, index) => (
                  <div className="flex  mt-3 gap-4">
                    <div className="flex flex-col">
                      <label className="mb-3 text-black font-bold text-[15px] capitalize">
                        attribute name
                      </label>
                      <input
                        type="text"
                        className="w-[354px] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5 resize-none"
                        name="attribute_name"
                        placeholder="attribute name"
                        autoComplete="off"
                        value={row.attribute_name}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="mb-3 text-black font-bold text-[15px] capitalize">
                        attribute value
                      </label>
                      <input
                        type="text"
                        className="w-[354px] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5 resize-none"
                        name="attribute_value"
                        placeholder="attribute value"
                        autoComplete="off"
                        value={row.attribute_value}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="mb-3 text-black font-medium h-[22.5px] capitalize"></label>
                      <div className="flex h-full items-center gap-1">
                        {index === rows.length - 1 && (
                          <button
                            className="bg-[#2688D1] rounded-full w-[22px] h-[22px] flex justify-center items-center"
                            onClick={addRow}
                          >
                            <Icon path={mdiPlus} size={1} color={"white"} />
                          </button>
                        )}
                        {rows.length > 1 && (
                          <button
                            className="rounded-full w-[24px] h-[24px] flex justify-center items-center"
                            onClick={() => removeRow(index)}
                          >
                            <Icon path={mdiClose} size={1.3} color={"red"} />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="text-end mt-4">
              <button
                type="button"
                className="rounded-lg text-[12px] outline outline-1 outline-[#1A1A1A2E] text-center font-medium w-[70px] h-[32px]"
                onClick={() => {
                  setOpenEditImagePropModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="ms-4 rounded-lg text-[12px] bg-[#2688D1] text-center font-medium text-white w-[70px] h-[32px]"
                onClick={save}
              >
                Save
              </button>
            </div>
          </div>
          {/* images list */}
        </div>
      </div>
      <ToastContainer autoClose={4000} pauseOnHover={false} theme="colored" />
    </div>
  );
};

export default CustomEditImagePropModal;
