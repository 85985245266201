import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useEditor } from "@grapesjs/react";
import {
  mdiBookmarkBoxOutline,
  mdiBookmarkCheckOutline,
  mdiDomain,
  mdiEyeOutline,
  mdiSendVariantOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { DevicesProvider } from "@grapesjs/react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
// import { googleLogout } from "@react-oauth/google";
import Select from "@mui/material/Select";
import {
  BTN_CLS,
  MAIN_BORDER_COLOR,
  cx,
  MAIN_TXT_COLOR,
  BTN_CLS_NEW,
  fetchDomainUrl,
  faqUnChecked,
  faqChecked,
} from "./common";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ProfileDropdown from "../Header/ProfileDropdown";
import {
  getAllSavedVariantsData,
  getExistingDomains,
  getSavedVariant,
  revertSavedVariant,
  saveUserVariant,
  updateSavedDataInEditor,
} from "../../actions/grapesjs";
import moment from "moment";
import CustomAddDomainModal from "./CustomAddDomainModal";
import CustomUseDomainModal from "./CustomUseDomainModal";
const Swal = require("sweetalert2");

const TopbarButtons = ({ className, rightToggelValue, showSidebar }) => {
  const user = useSelector((state) => state.auth);
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };
  const token = localStorage.getItem("user");
  const editor = useEditor();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const variantId = getQueryParam("v_id");
  const { save_template } = useSelector((state) => state.grapejs);
  const [, setUpdateCounter] = useState(0);
  const userData = JSON.parse(token);
  const { Commands } = editor;
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [openDrop, setOpenDrop] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [publishButtonLoading, setPublishButtonLoading] = useState(false);
  const [publishText, setPublishText] = useState("Publish");
  const [saveText, setSaveText] = useState("Save");
  const [savedVariantsData, setSavedVariantsData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [useDomainModal, setUseDomainModal] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUserDetail(user);
    dispatch(getAllSavedVariantsData(variantId, id))
      .then(async (response) => {
        setSavedVariantsData(response.data);
      })
      .catch((error) => {
        // Handle error as needed
        console.log("Error in fetching template", error);
      });
  }, []);

  // console.log(save_template,"-------save_template");

  let variant_updated_at = moment(save_template?.variant_updated_at);
  let updated_at = moment(save_template?.updated_at);
  useEffect(() => {
    calculateTimeDifference(save_template?.updated_at);
    if (!save_template?.publish_status) {
      if (variant_updated_at.isBefore(updated_at)) {
        setPublishButtonLoading(true);
      }
    }
  }, [save_template?.publish_status]);

  const calculateTimeDifference = (updated_at) => {
    if (updated_at) {
      const currentTime = moment();
      const updatedAtMoment = moment(updated_at);
      const differenceInMinutes = currentTime.diff(updatedAtMoment, "minutes"); // Get difference in minutes

      // Customize the message based on the difference
      let message = "";
      if (differenceInMinutes < 1) {
        message = "last saved just now";
      } else if (differenceInMinutes < 60) {
        message = `last saved ${differenceInMinutes} ${
          differenceInMinutes > 1 ? "minutes" : "minute"
          } ago`;
      } else if (differenceInMinutes < 1440) {
        // Less than 24 hours
        const hours = Math.floor(differenceInMinutes / 60);
        message = `last saved ${hours} ${hours > 1 ? "hours" : "hour"} ago`;
      } else {
        const days = Math.floor(differenceInMinutes / 1440); // Calculate the difference in days
        if (days > 0) {
          message = `last saved on ${updatedAtMoment.format("MMMM D, YYYY")}`;
        }
      }

      dispatch({ type: "SET_TIME_DIFFERENCE", payload: message });
    }
  };

  const [selectedLastSaved, setSelectedLastSaved] = useState("");

  const handleChange = (event) => {
    Swal.fire({
      html: "<div><p style='font-size:20px; font-weight:700; margin-bottom:20px'>Are you sure you'd like to revert back?</p><span style='font-size:14px; font-weight:400; margin-bottom:32px'>All unsaved changes will be lost.</span></div>",
      customClass: {
        confirmButton: "btn-success",
        cancelButton: "btn-danger",
        closeButton: "close-button",
      },
      icon: "",
      showCancelButton: true,
      confirmButtonText: "Yes, revert back",
      cancelButtonText: "No, don't revert",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setSelectedLastSaved(event.target.value);
        dispatch(revertSavedVariant(event.target.value))
          .then(async (response) => {
            window.location.reload(true);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    });
  };
  useEffect(() => {
    if (user?.editor_change_status) {
      setSaveButtonLoading(true); // Set loader status to true when changes are detected
    } else {
      // console.log("No changes detected");
      setSaveButtonLoading(false);
    }
    if (user?.editor_publish_status) {
      setPublishButtonLoading(true);
    }
  }, [user]);

  const userId = userData.user.id;
  const handleClick = (event) => {
    setOpenDrop(!openDrop);
    setAnchorEl(event.currentTarget);
  };
  // const handleLogout = async () => {
  //   try {
  //     if (userData?.user?.clientId) {
  //       await googleLogout();
  //       localStorage.clear();
  //       navigate("/login");
  //     } else {
  //       localStorage.clear();
  //       navigate("/login");
  //     }
  //   } catch (error) {
  //     console.error("Error during logout:", error);
  //   }
  // };

  const cmdButtons = [
    // {
    //   id: "core:component-outline",
    //   iconPath: mdiBorderRadius,
    //   class_name: "hidden md:block",
    // },
    // {
    //   id: "core:fullscreen",
    //   iconPath: mdiFullscreen,
    //   options: { target: "#root" },
    //   class_name: "block",
    // },
    //  {
    //   id: "core:open-code",
    //   iconPath: mdiXml,
    //   class_name: "hidden md:block",
    // },
    // {
    //   id: "core:undo",
    //   iconPath: mdiArrowULeftTop,
    //   disabled: () => !UndoManager.hasUndo(),
    // },
    // {
    //   id: "core:redo",
    //   iconPath: mdiArrowURightTop,
    //   disabled: () => !UndoManager.hasRedo(),
    // },
  ];

  useEffect(() => {
    const cmdEvent = "run stop";
    const updateEvent = "update";
    const updateCounter = () => setUpdateCounter((value) => value + 1);
    const onCommand = (id) => {
      cmdButtons.find((btn) => btn.id === id) && updateCounter();
    };
    editor.on(cmdEvent, onCommand);
    editor.on(updateEvent, updateCounter);

    return () => {
      editor.off(cmdEvent, onCommand);
      editor.off(updateEvent, updateCounter);
    };
  }, [editor]);

  const handelNavigate = async () => {
    const urlData = await fetchDomainUrl(save_template?.website_url);
    if (urlData) {
      window.open(`/${variantId}/${urlData}/index.html`, "_blank");
    }
  };

  const saveUserVariantData = async () => {
    try {
      setSaveText("Saving...");
      dispatch({ type: "EDITOR_CHANGE_STATUS", payload: false });
      setSaveButtonLoading(false);
      faqUnChecked(editor);
      const htmlCode = window.editor.getHtml();
      const cssCode = window.editor.getCss();

      await dispatch(
        saveUserVariant({
          userId: userId,
          website_id: id,
          variant_id: variantId ? variantId : null,
          html_data: htmlCode,
          css_data: cssCode,
          publish_status: false,
        })
      )
        .then(async (response) => {
          faqChecked(editor);
          editor.refresh();
          dispatch(
            getSavedVariant({
              id: id,
              variantId: variantId,
            })
          ).then((response) => {
            setSaveText("Save");
            setPublishButtonLoading(true);
            calculateTimeDifference(response.data.updated_at);
            dispatch(getAllSavedVariantsData(variantId, id))
              .then(async (response) => {
                setSavedVariantsData(response.data);
              })
              .catch((error) => {
                // Handle error as needed
                console.log("Error in fetching template", error);
              });
          }).catch((error) => {
            setSaveText("Save");
            dispatch({ type: "EDITOR_CHANGE_STATUS", payload: false });
            dispatch({ type: "EDITOR_PUBLISH_STATUS", payload: false });
            setPublishButtonLoading(false);
            setSaveButtonLoading(false);
            window.editor.setComponents('<div style="text-align:center; margin-top:50vh;"><div style=" font-size: 32px;font-weight: bold;line-height: 40px;background: linear-gradient( 135deg, #02bfd5 0%, #2688d1 28.21%, #f92672 100% );background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;">Website Got Deleted</div></div>');
            console.log(error, "error");
            // Handle error as needed
          });
        })
        .catch((error) => {
          console.log(error, "error");
          // Handle error as needed
        });
    } catch (error) {
      console.log("Error in fetching template", error);
    }
  };

  const publishUserVariantData = async () => {
    setPublishText("Publishing...");
    setPublishButtonLoading(false);

    try {
      dispatch(updateSavedDataInEditor(id, variantId)).then(
        async (response) => {
          if (!response) {
            dispatch({ type: "EDITOR_CHANGE_STATUS", payload: false });
            dispatch({ type: "EDITOR_PUBLISH_STATUS", payload: false });
            setPublishText("Publish");
            setPublishButtonLoading(false);
            setSaveButtonLoading(false);
            window.editor.setComponents('<div style="text-align:center; margin-top:50vh;"><div style=" font-size: 32px;font-weight: bold;line-height: 40px;background: linear-gradient( 135deg, #02bfd5 0%, #2688d1 28.21%, #f92672 100% );background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;">Website Got Deleted</div></div>');
            return;
          }
          dispatch(getExistingDomains(userId)).then(async (res) => {
            setPublishText("Publish");
            Swal.fire({
              title: "Your page has been published!",
              imageUrl: "/images/publish.svg",
              imageWidth: 166,
              imageHeight: 162.52,
              imageAlt: "Custom image",
              customClass: {
                htmlContainer: "htmlContainer",
                title: "custom-swal-title",
                confirmButton: "btn-gotit",
                closeButton: "close-button",
                denyButton: "btn-danger",
                cancelButton: "btn-danger",
                popup: "popup",
                image: "customimg",
              },
              html: `<div><span style="font-size:14px; font-weight:400; padding-bottom:8px;">Your page is live on a Joxy domain </span> <a style="font-size:14px; font-weight:700; color: #0D62A0;" href=${save_template?.publish_url
                } target="_blank">${save_template?.publish_url
                }</a></div><div id="cname-text" style="padding-top:12px; font-size:8px; font-weight:600; cursor:pointer;">CNAME: ${(save_template?.publish_url).replace(
                  "http://",
                  ""
                )}</div><div style="padding-top:12px; font-size:14px">Note: Clear your cache or do a hard refresh if you don't see the updates.</div>`,
              showDenyButton: true,
              showCancelButton: res.data?.length > 0 ? true : false,
              confirmButtonText: "Got it",
              denyButtonText: "Add A New Domain",
              cancelButtonText: "Use My Existing Connected Domain",
              showCloseButton: true,
            }).then((result) => {
              if (result.isDenied) {
                setIsModalOpen(true);
              }
              if (result.isDismissed && result.dismiss === "cancel") {
                setUseDomainModal(true);
              }
            });

            // Add this script after the Swal call to handle CNAME copy to clipboard
            document
              .getElementById("cname-text")
              .addEventListener("click", () => {
                const cnameText = (save_template?.publish_url).replace(
                  "http://",
                  ""
                );
                navigator.clipboard
                  .writeText(cnameText)
                  .then(() => {
                    console.log("CNAME copied to clipboard:", cnameText);
                  })
                  .catch((err) => {
                    console.error("Failed to copy CNAME: ", err);
                  });
              });
          });
        }
      );
    } catch (error) {
      console.log("Error in fetching template", error);
    }
  };

  const manageDomains = async () => {
    dispatch(getExistingDomains(userId)).then(async (res) => {
      Swal.fire({
        title: "Manage domains",
        customClass: {
          htmlContainer: "htmlContainer",
          title: "custom-swal-title",
          confirmButton: "btn-gotit",
          closeButton: "close-button",
          denyButton: "btn-danger",
          cancelButton: "btn-danger",
          popup: "popup",
          image: "customimg",
        },
        showConfirmButton: false,
        showDenyButton: true,
        showCancelButton: res.data?.length > 0 ? true : false,
        denyButtonText: "Add A New Domain",
        cancelButtonText: "Use My Existing Connected Domain",
        showCloseButton: true,
      }).then((result) => {
        if (result.isDenied) {
          setIsModalOpen(true);
        }
        if (result.isDismissed && result.dismiss === "cancel") {
          setUseDomainModal(true);
        }
      });
    })
  }

  return (
    <div className={cx("flex items-center gap-3", className)}>
      <div className="flex items-center">
        <span className="w-max text-black font-medium text-[12px] capitalize ">
          {user?.setTimeDifference}
        </span>
      </div>
      <button
        className={cx(
          BTN_CLS,
          saveButtonLoading
            ? "button-gradiant"
            : `${MAIN_BORDER_COLOR} opacity-50 cursor-not-allowed`,
          MAIN_TXT_COLOR
        )}
        style={{ fontSize: "12px" }}
        onClick={saveButtonLoading ? () => saveUserVariantData() : null}
      >
        <div className="flex items-center justify-center py-1.5">
          <span className="me-1">
            <Icon size={0.6} path={mdiBookmarkCheckOutline} />
          </span>
          {saveText}
        </div>
      </button>
      <button
        className={cx(
          BTN_CLS,
          publishButtonLoading
            ? "button-gradiant"
            : `${MAIN_BORDER_COLOR} opacity-50 cursor-not-allowed`,
          MAIN_TXT_COLOR
        )}
        style={{ fontSize: "12px" }}
        onClick={publishButtonLoading ? () => publishUserVariantData() : null}
      >
        <div className="flex justify-center py-1.5">
          <span className="me-1">
            <Icon
              size={0.6}
              className="rotate-[-29deg]"
              path={mdiSendVariantOutline}
            />
          </span>
          {publishText}
        </div>
      </button>

      <button className="border border-black rounded-lg flex items-center ps-3">
        {/* <Icon size={0.7} path={mdiEyeOutline} color={'black'}/> */}
        <DevicesProvider>
          {({ selected, select, devices }) => (
            <FormControl
              size="small"
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "12px",
                  fontFamily: '"CeraProRegular", sans-serif',
                  color: "black",
                  border: "none",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            >
              <Select
                value={selected}
                onChange={(ev) => select(ev.target.value)}
                sx={{
                  "& .MuiSelect-icon": {
                    color: "black",
                  },
                  [`& .MuiSelect-select`]: {
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    paddingLeft: "6px",
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  sx: {
                    [`& .MuiMenu-paper`]: {
                      borderRadius: "8px",
                      // left:'48.7% !important',
                      minWidth: "98px !important",
                      maxWidth: "98px",
                    },
                    [`& .MuiMenu-list`]: {
                      paddingTop: 0,
                      paddingBottom: 0,
                      background: "black",
                      fontSize: "12px",
                      "& li": {
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      },
                    },
                  },
                }}
              >
                <MenuItem
                  value=""
                  sx={{ fontSize: "8px", fontWeight: "500", display: "none" }}
                  disabled={true}
                >
                  <span className="me-1">
                    <Icon size={0.7} path={mdiEyeOutline} />
                  </span>
                  Preview
                </MenuItem>
                {devices.map((device) => (
                  <MenuItem
                    value={device.id}
                    key={device.id}
                    sx={{
                      fontSize: "8px",
                      fontWeight: "500",
                      borderTop: "1px solid #1F1F1F",
                    }}
                  >
                    {device.getName()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DevicesProvider>
      </button>

      {savedVariantsData.length > 1 && (
        <button>
          <FormControl
            size="small"
            sx={{
              minWidth: 120,
              "& .MuiInputBase-root": {
                fontSize: "12px",
                fontFamily: '"CeraProRegular", sans-serif',
                color: "black",
                border: "none",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                  borderRadius: "8px",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
          >
            <Select
              value={selectedLastSaved}
              onChange={handleChange}
              displayEmpty
              sx={{
                "& .MuiSelect-icon": {
                  color: "black",
                },
                [`& .MuiSelect-select`]: {
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "6px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                sx: {
                  [`& .MuiMenu-paper`]: {
                    borderRadius: "8px",
                  },
                  [`& .MuiMenu-list`]: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    background: "black",
                    fontSize: "12px",
                    "& li": {
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    },
                  },
                },
              }}
            >
              <MenuItem
                value=""
                sx={{ fontSize: "8px", fontWeight: "500", display: "none" }}
                disabled={true}
              >
                <span className="me-1">
                  <Icon size={0.6} path={mdiBookmarkBoxOutline} />
                </span>
                Revert To Last Saved
              </MenuItem>
              {savedVariantsData?.map(
                (variantData) =>
                  variantData.saved_at && (
                    <MenuItem
                      value={variantData._id}
                      key={variantData._id}
                      sx={{
                        fontSize: "8px",
                        fontWeight: "500",
                        borderTop: "1px solid #1F1F1F",
                      }}
                    >
                      {moment(variantData.saved_at).format(
                        "DD-MMM-YYYY HH:mm:ss "
                      )}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </button>
      )}

      <button
        className={cx(
          BTN_CLS,
          save_template?.website_url
            ? "button-gradiant"
            : `${MAIN_BORDER_COLOR} opacity-50 cursor-not-allowed`,
          MAIN_TXT_COLOR
        )}
        style={{ fontSize: "12px" }}
        onClick={save_template?.website_url ? () => handelNavigate() : null}
      >
        <div className="flex justify-center py-1.5">
          <span className="me-1">
            <Icon size={0.7} path={mdiEyeOutline} />
          </span>{" "}
          Preview
        </div>
      </button>
      {/* <button
        className={cx(
          BTN_CLS,
          save_template?.website_url
            ? "button-gradiant"
            : `${MAIN_BORDER_COLOR} opacity-50 cursor-not-allowed`,
          MAIN_TXT_COLOR,
          "whitespace-nowrap p-[inherit] w-[auto]"
        )}
        style={{ fontSize: "12px" }}
        onClick={() => { manageDomains() }}
      >
        <div className="flex justify-center py-1.5">
          <span className="me-1">
            <Icon size={0.7} path={mdiDomain} />
          </span>{" "}
          Manage Domains
        </div>
      </button> */}
      {cmdButtons.map(
        ({ class_name, id, iconPath, disabled, options = {} }) => (
          <button
            key={id}
            type="button"
            className={cx(
              "hover:text-[black]",
              class_name,
              BTN_CLS_NEW,
              MAIN_BORDER_COLOR,
              Commands.isActive(id) && "text-[black]",
              disabled?.() && "opacity-50"
            )}
            onClick={() =>
              Commands.isActive(id)
                ? Commands.stop(id)
                : Commands.run(id, options)
            }
            disabled={disabled?.()}
            style={{ border: "none" }}
          >
            <Icon path={iconPath} size="20px" color="#000" />
          </button>
        )
      )}
      <p
        onClick={handleClick}
        className="text-black items-center capitalize text-xs cursor-pointer font-bold flex"
      >
        <img
          src="/images/new_theme/dummy_person.png"
          className="h-8 mr-1.5 rounded-full border-2 border-[#86878D]"
          alt=""
        />
        {userDetail?.user?.name}
        {openDrop ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        {/* {openDrop ? <Icon size={0.7} path={mdiMenuUp} /> : <Icon size={0.7} path={mdiMenuDown} />} */}
      </p>
      <ProfileDropdown
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        userDetail={userDetail}
        open={openDrop}
        setOpen={setOpenDrop}
        top="50px"
      />
      {/* <button>
        {showSidebar && (
          <button
            className={cx(BTN_CLS, MAIN_BORDER_COLOR)}
            style={{border: "none"}}
            onClick={() => rightToggelValue(false)}
          >
            <Icon path={mdiMenuClose} size={1} />
          </button>
        )}
        {!showSidebar && (
          <button
            className={cx(BTN_CLS, MAIN_BORDER_COLOR)}
            style={{border: "none"}}
            onClick={() => rightToggelValue(true)}
          >
            <Icon path={mdiMenuOpen} size={1} />
          </button>
        )}
      </button> */}
      {isModalOpen && (
        <CustomAddDomainModal
          setIsModalOpen={setIsModalOpen}
          publish_url={save_template?.publish_url}
          setUseDomainModal={setUseDomainModal}
        />
      )}
      {useDomainModal && (
        <CustomUseDomainModal
          setUseDomainModal={setUseDomainModal}
          publish_url={save_template?.publish_url}
        />
      )}
    </div>
  );
};

export default TopbarButtons;
