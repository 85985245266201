import React, { useState, useEffect } from "react";
import basestyle from "../Base.module.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  login,
  googleLogin as googleLoginAction,
} from "../../actions/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useGoogleAuth from "../../hooks/useGoogleAuth";
import { PageLoaderJoxy } from "../library/LoaderJoxy";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [user, setUserDetails] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [showPassword, setShowPassword] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const changeHandler = (e) => {
    const { name, value, type, checked } = e.target;
    setUserDetails({
      ...user,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Please enter a valid email address";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const loginHandler = (e) => {
    e.preventDefault();
    const errors = validateForm(user);
    setFormErrors(errors);
    // if (Object.keys(errors).length === 0) {
      setIsSubmit(true);
    // }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(login(user))
        .then(async (response) => {
          window.location.reload();
          
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }, [formErrors]);

  const {googleLogin, isGoogleLoading} = useGoogleAuth(
    () => navigate("/", { replace: true }),
    dispatch,
    googleLoginAction
  );

  return (
    <>
    
      <div className={`${basestyle.LoginFormBackground} grid`}>
      {isGoogleLoading ? (
        <PageLoaderJoxy/>
      ) : (
        <div className="xl:m-0 m-auto">
          <div className="mb-7">
            <img
              src="/images/new_theme/Joxy.svg"
              className="scrapper-logo-color"
              alt="joxy"
            />
          </div>
          <form className={basestyle.userForm}>
            <h1 className="font-cera-pro-bold text-xl font-bold leading-[33.6px] pb-7 text-center">
              Login
            </h1>
            <div className="mb-3 w-full">
              <label
                htmlFor="email"
                className="font-cera-pro-bold block text-sm font-medium leading-6 mb-2"
              >
                Email Address
              </label>
              <div className={`relative ${basestyle.formInputBox}  w-full`}>
                <div className="inset-y-0 left-0 pr-3 flex items-center text-sm leading-5">
                  <img
                    className={`h-6 text-gray-700 cursor-pointer`}
                    src="/images/new_theme/sms-tracking.svg"
                    alt="sms-tracking"
                  />
                  <input
                    className="w-full pl-[7.18px]  focus-visible:outline-none"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Email Address"
                    onChange={changeHandler}
                    value={user.email}
                  />
                </div>
              </div>
              <p className={basestyle.error}>{formErrors.email}</p>
            </div>
            <div className="mb-2 md:w-full" x-data="{ show: true }">
              <label
                htmlFor="password"
                className="font-cera-pro-bold block text-sm font-medium leading-6 mb-2"
              >
                Password
              </label>
              <div className={`relative ${basestyle.formInputBox}  w-full`}>
                <div className="inset-y-0 left-0 pr-3 flex items-center text-sm leading-5">
                  <img
                    className={`h-6 text-gray-700 cursor-pointer`}
                    src="/images/new_theme/lock_icon.svg"
                    alt="lock"
                  />
                  <input
                    type={showPassword ? "password" : "text"}
                    className="w-full pl-[7.18px] focus-visible:outline-none"
                    name="password"
                    id="password"
                    placeholder="Enter Password"
                    onChange={changeHandler}
                    value={user.password}
                  />
                </div>
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                  <img
                    className={`h-6 text-gray-700 cursor-pointer ${
                      showPassword ? "hidden" : "block"
                    }`}
                    onClick={togglePasswordVisibility}
                    src="/images/new_theme/eye.svg"
                    alt="eye"
                  />

                  <img
                    className={`h-6 text-gray-700 cursor-pointer ${
                      showPassword ? "block" : "hidden"
                    }`}
                    fill="none"
                    onClick={togglePasswordVisibility}
                    src="/images/new_theme/eye-off.svg"
                    alt="eye-off"
                  />
                </div>
              </div>

              <p className={basestyle.error}>{formErrors.password}</p>
            </div>
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="remember-me"
                  name="rememberMe"
                  onChange={changeHandler}
                  value={user.rememberMe}
                  className="mr-1 checked:bg-blue-600 checked:border-transparent rounded-lg border-black"
                />
                <label
                  htmlFor="remember-me"
                  className="text-black leading-[21px] text-xs font-normal"
                >
                  Remember Me
                </label>
              </div>
              <a
                href="/forgot-password"
                className="text-[#2688D1] leading-[21px] text-xs underline"
              >
                Forgot your password?
              </a>
            </div>
            <button
               className={`font-cera-pro-bold w-full ${basestyle.button_common}`}
              onClick={loginHandler}
            >
              Log In
            </button>
            <div className="mt-8 flex flex-row justify-center">
              <span className="absolute bg-white px-4 text-black">
                Or with Social Profile
              </span>
              <div className="mt-3 h-px w-full bg-[#6C757D]"></div>
            </div>
            <div className="flex justify-center mt-8 mr-2">
              <img
                onClick={googleLogin}
                className="mr-3 cursor-pointer"
                src="/images/new_theme/google.svg"
                alt="Google Login"
              />
               <img
                className="mr-3"
                src="/images/new_theme/facebook.svg"
                alt="facebook"
              />
              <img
                className="mr-3"
                src="/images/new_theme/twitter.svg"
                alt="twitter"
              />
              <img src="/images/new_theme/linkedin.svg" alt="linkedin" />
            </div>
            <div className="mt-6 text-center text-black">
              Not yet registered?{" "}
              <a href="/signup" className="text-[#2688D1] underline">
                Register Now
              </a>
            </div>
          </form>
        </div>
        )}
      </div>
      
      <ToastContainer
            autoClose={2000}
            pauseOnHover={false}
            theme="colored"
          />
    </>
  );
};

export default Login;
