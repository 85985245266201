import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Routes,
  Route,
  useMatch,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./components/dashbaord";
import GenerationFlow from "./components/generation-flow";
import WebBuilder from "./components/WebBuilder";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import PreviewPage from "./pages/PreviewPage";
import PublishPage from "./pages/PublishPage";
import DynamicContent from "./pages/DynamicContent";
import WebsiteList from "./components/WebsiteList";
import Footer from "./components/Footer";
import { getUserSubscriptionStatus, saveUserDetails } from "./actions/auth";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ChangePasword from "./components/ForgotPassword/ChangePassword";
import "./App.css";
import Subscribe from "./components/Subscription/Subscribe";
import PaymentMessage from "./components/Subscription/PaymentMessage";
import CreateSession from "./components/CreateSession/CreateSession";
import NoMobile from "./components/NoMobile";
import SSLCertificateUpload from "./components/SSLCertificateUpload";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondition from "./pages/TermsAndCondition";
import DomainList from "./components/DomainList";
import DomainInstructions from "./components/DomainInstructions";
import ErrorPage from "./pages/ErrorPage";

const App = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);

  const path = location.pathname;
  const dispatch = useDispatch();

  // Detect if user is on a mobile device
  useEffect(() => {
    const checkMobile = () => {
      const isMobileDevice =
        window.innerWidth <= 768 || /Mobi|Android/i.test(navigator.userAgent);
      setIsMobile(isMobileDevice);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("token");
    console.log(user, "user");
    if (user) {
      dispatch(saveUserDetails());
      dispatch(getUserSubscriptionStatus())
        .then((res) => {
          const subscription = res.data;
          setSubscriptionData(subscription);
          if (
            subscription &&
            (!subscription.endDate ||
              new Date(subscription.endDate) <= new Date() ||
              subscription.status !== "active")
          ) {
            // if route is not payment-message then navigate to subscribe
            if (!location.pathname.includes("payment-message"))
              navigate("/subscribe", { replace: true });
            return;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [dispatch, navigate]);

  // Real-time logout synchronization
  useEffect(() => {
    const logoutChannel = new BroadcastChannel("logout-channel");

    logoutChannel.onmessage = (event) => {
      if (event.data === "logout") {
        localStorage.clear();
        navigate("/login", { replace: true });
      }
    };

    return () => {
      logoutChannel.close();
    };
  }, [navigate]);

  const token = localStorage.getItem("user");
  const subscriptionStatus =
    subscriptionData || JSON.parse(localStorage.getItem("subscriptionStatus"));
  const isWebBuilderRoute = useMatch("/web-builder/:id");
  const isWebSiteRoute = useMatch("/:userId/:websiteName/index.html");
  const isWebSiteRoute1 = useMatch("/:variantId/:websiteName/index.html");
  const isWebSiteRoute2 = useMatch(
    "/:userId/:websiteId/:websiteName/index.html"
  );

  return (
    <div>
      <Routes>
        {token ? (
          subscriptionStatus &&
          new Date(subscriptionStatus.endDate) > new Date() ? (
            <>
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/:paymentStatus?' element={<Dashboard />} />
              <Route path='/page-processing/:id' element={<GenerationFlow />} />
              <Route
                path='/web-builder/:id'
                element={isMobile ? <NoMobile /> : <WebBuilder />} // Conditional rendering
              />
              <Route path='/website-list' element={<WebsiteList />} />
              <Route
                path='/payment-message/:paymentStatus'
                element={<PaymentMessage />}
              />
              <Route
                path='/sslcertificate'
                element={<SSLCertificateUpload />}
              />
              <Route path='/my-domains' element={<DomainList />} />
              <Route
                path='/domain-instructions'
                element={<DomainInstructions />}
              />
              <Route path='*' element={<Navigate to='/dashboard' replace />} />
            </>
          ) : (
            <>
              <Route
                path='/payment-message/:paymentStatus'
                element={<PaymentMessage />}
              />
              <Route path='/my-domains' element={<DomainList />} />
              <Route path='/subscribe' element={<Subscribe />} />
              <Route
                path='/sslcertificate'
                element={<SSLCertificateUpload />}
              />
              <Route path='*' element={<Navigate to='/subscribe' replace />} />
            </>
          )
        ) : (
          <>
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Register />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/change-password' element={<ChangePasword />} />
            <Route path='*' element={<Navigate to='/login' replace />} />
          </>
        )}

        <Route
          path='/:variantId/:websiteName/index.html'
          element={<PreviewPage />}
        />
        <Route
          path='/:userId/:websiteName/index.html'
          element={<DynamicContent />}
        />

        <Route
          path='/:userId/:websiteId/:websiteName/index.html'
          element={<PublishPage />}
        />

        <Route path='/byadmin/redirection/:id' element={<CreateSession />} />
        <Route path='TermsAndCondition' element={<TermsAndCondition />} />
        <Route path='PrivacyPolicy' element={<PrivacyPolicy />} />
        <Route path='errorPage' element={<ErrorPage />} />

        {/* Add more routes as needed */}
      </Routes>
      {token &&
        !isWebBuilderRoute &&
        !isWebSiteRoute &&
        !isWebSiteRoute2 &&
        !isWebSiteRoute1 && <Footer url={path} />}
    </div>
  );
};

export default App;
