/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { CircularProgress } from "@mui/material";
import {
  generateImage,
  getGeneratedImages,
  getImageProgress,
  imageControls,
  removeBackground,
} from "../../actions/image";
import { useDispatch } from "react-redux";
import { ImageNameExtractor } from "./common";
import { uploadImageToS3Bucket } from "../../actions/s3bucket";
import { useLocation, useParams } from "react-router-dom";
import { ButtonLoaderJoxy, PageLoaderJoxy } from "./LoaderJoxy";
import { ToastContainer, toast } from "react-toastify";

const CustomGenerateImageModal = ({
  setIsCreateImageModalOpen,
  setOpenAddBackgroundModal,
  editorRef,
  setAddBackgroundImageUrl,
  setSelectedImage,
  setModalOpen,
  getNewImageProgress,
  messageId,
  setOriginalImage,
  ...props
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [generatedImageUrl, setGeneratedImageUrl] = useState();
  const [prompt, setPrompt] = useState("");
  const [savingImage, setSavingImage] = useState(false);
  const [generatingImage, setGeneratingImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [assets, setAssets] = useState([
    // "/images/wood1.jpg",
    // "/images/wood2.jpg",
    // "/images/wood3.jpg",
    // "/images/wood4.jpg",
    // "/images/wood5.jpg",
    // "/images/wood6.jpg",
  ]);
  const [removeBackgroundLoader, setRemoveBackgroundLoader] = useState(false);
  const [backgroundRemoved, setBackgroundRemoved] = useState(false);
  const [imageControlLoader, setImageControlLoader] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  // Function to extract query parameter from the URL
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const token = localStorage.getItem("user");
  // Extract the v_id from the query string
  const variantId = getQueryParam("v_id");

  // Parse the JSON string to get the object
  const userData = JSON.parse(token);
  // Access the "id" property from the user object
  const userId = userData.user.id;

  useEffect(() => {
    if (messageId) {
      setGeneratingImage(true);
      setErrorMsg(false);
      setPrompt(messageId.prompt);
      async function fetchData() {
        const res = await getNewImageProgress(messageId.messageId);
        manageResponse(res);
      }
      fetchData();
    }
    setLoading(true);
    getGeneratedData();
  }, []);

  const getGeneratedData = async () => {
    dispatch(getGeneratedImages(userId, id))
      .then((res) => {
        // console.log({ res });
        setAssets(res.data);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const generateNewImage = async () => {
    if(!prompt){
      toast.error("Please enter prompt");
      return ;
    }
    setGeneratingImage(true);
    setErrorMsg(false)
    dispatch(generateImage({ prompt, userId, variantId, websiteId: id }))
      .then(async (response) => {
        const res = await getNewImageProgress(response.data.messageId);
        manageResponse(res);
      })
      .catch((error) => {
        console.error(error.message);
        setGeneratingImage(false);
        // Handle error as needed
      });
  };

  // const getNewImageProgress = async (messageId) => {
  //   const interval = setInterval(async () => {
  //     dispatch(getImageProgress(messageId))
  //       .then((response) => {
  //         if (
  //           response.data.progress === 100 ||
  //           response.data.status === "FAIL"
  //         ) {
  //           response.data.status === "FAIL" ? setErrorMsg(true) : setImageData(response.data);
  //           clearInterval(interval);
  //           setImageControlLoader("");
  //           setGeneratingImage(false);
  //         }
  //         if (response.data.uri) {
  //           setGeneratedImageUrl(response.data.uri);
  //           if (
  //             response.data.progress === 100 &&
  //             !assets.includes(response.data.uri)
  //           ) {
  //             setAssets((prevAssets) => [...prevAssets, response.data.uri]);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error.message);
  //         clearInterval(interval);
  //         // Handle error as needed
  //       });
  //   }, 2000);
  // };

  const handleImageControls = async (button) => {
    const data = {
      messageId: imageData.messageId,
      button: button,
      userId,
      variantId,
      websiteId: id
    };
    setImageControlLoader(button);
    setErrorMsg(false)
    dispatch(imageControls(data))
      .then(async (response) => {
        setImageSelected(true);
        const res = await getNewImageProgress(response.data.messageId);
        manageResponse(res);
      })
      .catch((error) => {
        console.error(error.message);
        // Handle error as needed
      });
  };

  const editImage = async(img)=>{
    const res = await getNewImageProgress(img.messageId);
      manageResponse(res);
  }

  const manageResponse = (res) => {
    if (res.data.progress === 100 || res.data.status === "FAIL") {
      res.data.status === "FAIL" ? setErrorMsg(true) : setImageData(res.data);
      setImageControlLoader("");
      setGeneratingImage(false);
    }
    if (res.data.imageUri) {
      setGeneratedImageUrl(res.data.imageUri);

      if (res.data.progress === 100 && !assets.includes(res.data.imageUri)) {
        // setAssets((prevAssets) => [...prevAssets, res.data]);
        setLoading(true);
        getGeneratedData();
      }
    }
  };

  const removeImageBackground = async () => {
    setRemoveBackgroundLoader(true);
    dispatch(removeBackground(await convertImageToDataURL()))
      .then(async (response) => {
        // console.log({response});
        setRemoveBackgroundLoader(false);
        setGeneratedImageUrl(response.data.image);
        setBackgroundRemoved(true);
      })
      .catch((error) => {
        console.error(error.message);
        setRemoveBackgroundLoader(false);
        // Handle error as needed
      });
  };

  const convertImageToDataURL = async () => {
    try {
      const response = await fetch(generatedImageUrl);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);
        };

        reader.onerror = () => {
          reject(new Error("Error reading blob as Data URL"));
        };

        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to Data URL:", error);
    }
  };

  const getImage = async (dataURL) => {
    setSavingImage(true);
    // Extract the file extension using a regular expression
    const extension = generatedImageUrl.match(/\.(\w+)(\?|$)/)[1];
    // console.log({ extension });

    var imageName = ImageNameExtractor({ imageUrl: generatedImageUrl });
    if (imageName.name.length > 100) {
      imageName.name = Date.now() + "." + extension;
    }
    const file = await convertImageToDataURL();
    dispatch(
      uploadImageToS3Bucket({
        file: file,
        folder_name: id,
        file_name: imageName.name,
      })
    )
      .then(async (response) => {
        if (response.data.file) {
          const selectedImage = editorRef.current.getSelected();
          editorRef.current.AssetManager.add(response.data.file);
          selectedImage.set("src", response.data.file);
          setSavingImage(false);
          setIsCreateImageModalOpen(false);
        }
      })
      .catch((error) => {
        // Handle error as needed
        setSavingImage(false);
        console.log("Error in fetching s3 bucket images", error);
      });
  };

  return (
    <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-7 rounded relative w-[1200px]">
        <div className="flex   justify-between">
          <button className="flex items-center text-[14px] gap-1.5 mb-[15px]"
            onClick={() => {
              setTimeout(() => {
                setIsCreateImageModalOpen(false);
                editorRef.current.runCommand("open-assets");
              }, 500); // Wait for fade-out transition
            }}
          >
            <img src="/images/arrow.svg" alt="arrow" className="w-[14px]" />
            Back
          </button>
          <button
            type="button"
            onClick={() => {
              setIsCreateImageModalOpen(false);
            }}
          >
            <Icon size={1} path={mdiClose} />
          </button>
        </div>
        <div className="absolute flex justify-between w-[95%]">
          <h2>Generate AI Images</h2>
        </div>
        <div className="mt-12">
          <span className="font-bold text-[12px]">Prompts</span>
          <div className="mt-1">
            <input
              type="text"
              onChange={(e) => setPrompt(e.target.value)}
              className="w-[1026px] h-[40px] p-3 rounded-lg bg-[#F1F4F9] me-3"
              name="prompt"
              placeholder="Generate will generate image using prompts"
              readOnly={generatingImage}
            />
            {generatingImage ? (
              <button
                type="button"
                className="rounded-lg bg-[#2688D1] px-2 text-center text-sm font-medium text-white w-[103px] h-10"
                disabled
              >
                <ButtonLoaderJoxy />
                {/* <CircularProgress size={16} color="inherit" /> */}
              </button>
            ) : (
              <button
                type="button"
                className="rounded-lg bg-[#2688D1] px-2 text-center text-sm font-medium text-white w-[103px] h-10"
                onClick={generateNewImage}
              >
                Generate
              </button>
            )}
          </div>
        </div>
        <div className="flex mt-5 w-[1138px] h-[385px]">
          <div className="crop-section overflow-y-auto w-[878px] h-[100%]">
            {
              generatedImageUrl ? (
                <div id="cropper-container">
                  <img
                    className="w-[270px] h-[270px]"
                    id="generatedImage"
                    src={generatedImageUrl}
                    alt="generatedImage"
                  />
                  {imageData && imageData.buttons && (
                    <div className="grid grid-cols-5 w-[50%] gap-2.5 mt-2.5">
                      {imageData.buttons.map((button, index) => (
                        <>
                          {imageControlLoader === button ? (
                            <button
                              className="rounded-lg outline text-center text-sm font-medium  bg-[#2688D1] text-white "
                              key={index}
                              disabled
                            >
                              <ButtonLoaderJoxy />
                              {/* <CircularProgress size={12} color="inherit" /> */}
                            </button>
                          ) : (
                            <button
                              className="rounded-lg outline text-center text-sm font-medium hover:bg-[#2688D1]  focus:bg-[#2688D1] hover:text-white focus:text-white "
                              key={index}
                              onClick={() => {
                                handleImageControls(button);
                              }}
                            >
                              {button}
                            </button>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                  {errorMsg && (
                    <p className="mt-2 text-[red]">
                      Failed to Produce Image. Please Retry
                    </p>
                  )}
                  {generatedImageUrl &&
                    !generatingImage &&
                    !imageControlLoader && (
                      <div className="mt-5 text-right">
                        {/* <button
                      type="button"
                      className="rounded-lg bg-[#2688D1] p-2.5 text-center text-sm font-medium text-white w-20"
                      onClick={() => {
                        setAddBackgroundImageUrl(generatedImageUrl);
                        setIsCreateImageModalOpen(false);
                        setOpenAddBackgroundModal(true);
                      }}
                    >
                      Edit
                    </button> */}
                        <button
                          type="button"
                          className="ms-5 rounded-lg bg-[#2688D1] p-2.5 text-center text-sm font-medium text-white w-20"
                          onClick={() => {
                            setSelectedImage(generatedImageUrl);
                            setOriginalImage(generatedImageUrl);
                            setIsCreateImageModalOpen(false);
                            setModalOpen(true);
                          }}
                          disabled={savingImage}
                        >
                          Edit
                        </button>
                        {savingImage ? (
                          <button
                            type="button"
                            className="ms-3 rounded-lg bg-[#2688D1] p-2.5 text-center text-sm font-medium text-white w-20"
                            disabled
                          >
                            {/* <CircularProgress size={16} color="inherit" /> */}
                            <ButtonLoaderJoxy />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="ms-3 rounded-lg bg-[#2688D1] p-2.5 text-center text-sm font-medium text-white w-20"
                            // onClick={getImage}
                            onClick={() => {
                              setSelectedImage(generatedImageUrl);
                              setOriginalImage(generatedImageUrl);
                              setIsCreateImageModalOpen(false);
                              setModalOpen(true);
                            }}
                          >
                            Save
                          </button>
                        )}
                        {!backgroundRemoved ? (
                          <>
                            {removeBackgroundLoader ? (
                              <button
                                type="button"
                                className="ms-3 rounded-lg bg-[#2688D1] p-2.5 text-center text-sm font-medium text-white w-40"
                                disabled
                              >
                                {/* <CircularProgress size={16} color="inherit" /> */}
                                <ButtonLoaderJoxy />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="ms-3 rounded-lg bg-[#2688D1] p-2.5 text-center text-sm font-medium text-white"
                                onClick={removeImageBackground}
                                disabled={savingImage}
                              >
                                Remove Background
                              </button>
                            )}
                          </>
                        ) : (
                          <button
                            type="button"
                            className="ms-3 rounded-lg bg-[#2688D1] p-2.5 text-center text-sm font-medium text-white"
                            onClick={() => {
                              setAddBackgroundImageUrl(generatedImageUrl);
                              setIsCreateImageModalOpen(false);
                              setOpenAddBackgroundModal(true);
                            }}
                            disabled={savingImage}
                          >
                            Add AI Background
                          </button>
                        )}
                      </div>
                    )}
                </div>
              ) : (
                <div className="generating-text text-center">
                  {generatingImage ? (
                    <div>
                      <h2>
                        Generating image for <b>{prompt}</b>
                        <ButtonLoaderJoxy />
                      </h2>
                      <p className="mt-3 text-[12px]">
                        Feel free to navigate away from this screen and your
                        image will keep generating. You can come back, and the
                        generated image will be visible in the saved images
                        section 👉
                      </p>
                    </div>
                  ) : (
                    <p>Enter a prompt and watch the magic happen! 🪄</p>
                  )}
                </div>
              )
              //  (
              //   <div className="grid grid-cols-2 w-[25%]">
              //     <img src="/images/U11.svg" alt="noimage" />
              //     <img src="/images/U12.svg" alt="noimage" />
              //     <img src="/images/U13.svg" alt="noimage" />
              //     <img src="/images/U14.svg" alt="noimage" />
              //   </div>
              // )
            }
          </div>

          <div className="h-[86.5%]">
            {/* <div className="grid grid-cols-3 ms-3 mt-3 gap-1.5">
              {assets.map((img) => (
                <img
                  className="w-[76px] h-[76px] rounded"
                  src={img}
                  alt="background"
                />
              ))}
            </div> */}
            {loading ? (
              <div className="flex ms-3 w-[248px] h-[95%] content-center flex-wrap">
                <ButtonLoaderJoxy />
              </div>
            ) : (
              <>
                {assets.length === 0 && !loading ? (
                  <div className="flex ms-3 w-[248px] h-[95%] content-center flex-wrap">
                    You haven't generated anything using AI yet.
                  </div>
                ) : (
                  <>
                    <div className="ms-3 grid">
                      <span className="font-bold text-sm">
                        AI Generated Images
                      </span>
                      <span className="text-[10px]">
                        (To modify a previously generated image click edit)
                      </span>
                    </div>
                    <div className="max-h-[100%] grid-container grid grid-cols-3 ms-3 mt-3 gap-1.5 overflow-y-auto">
                      {assets.map((img, index) => (
                        <div
                          key={index}
                          className="image-box w-[76px] h-[76px] rounded logo-trans"
                        >
                          <img
                            className="image"
                            src={img.thumbnailUri}
                            alt="background"
                          />
                          <div className="overlay w-[76px] h-[76px] rounded">
                            <button
                              className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                              // onClick={() => {
                              //   setGeneratedImageUrl(img);
                              //   setImageData(null);
                              // }}
                              onClick={() => {
                                setTimeout(() => {
                                  setIsCreateImageModalOpen(false);
                                  setSelectedImage(img.imageUri);
                                  setOriginalImage(img.imageUri);
                                  setModalOpen(true);
                                }, 500); // Wait for fade-out transition
                              }}
                              disabled={savingImage}
                            >
                              Select
                            </button>
                            <button
                              className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                              onClick={() => {
                                editImage(img);
                              }}
                              disabled={savingImage}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    </div>
  );
};

export default CustomGenerateImageModal;
