import { CLICK_SUCCESS, CLICK_FAILURE, UPDATE_VARIANT_FAILURE, UPDATE_VARIANT_SUCCESS, TEMPLATE_VARIANTS_SUCCESS, TEMPLATE_VARIANTS_FAILURE, REMOVE_SECTION_SUCCESS, REMOVE_SECTION_FAILURE, WEBSITE_LIST_FAILURE, WEBSITE_LIST_SUCCESS, TEMPLATE_SUCCESS, TEMPLATE_FAILURE, TEMPLATE_1_SUCCESS, TEMPLATE_1_FAILURE, USER_WEBSITE_FAILURE, USER_WEBSITE_SUCCESS, UPDATE_EDITOR_FAILURE, UPDATE_EDITOR_SUCCESS, USER_COLOR_SUCCESS,
  USER_COLOR_FAILURE,USER_TEMPLATE_SUCCESS, USER_WEBSITE_PUBLISH_SUCCESS, USER_WEBSITE_PUBLISH_FAILURE,USER_TEMPLATE_FAILURE, SAVE_USER_VARIANT_FAILURE, SAVE_USER_VARIANT_SUCCESS, SAVE_EDITOR_FAILURE,SAVE_EDITOR_SUCCESS, SAVED_TEMPLATE_SUCCESS, SAVED_TEMPLATE_FAILURE,UPDATE_VARIANT_TITLE_SUCCESS,  UPDATE_VARIANT_TITLE_FAILURE, PREVIEW_SUCCESS, PREVIEW_FAILURE,ADD_NEW_VARIANTS_SUCCESS, ADD_NEW_VARIANTS_FAILURE, 
  DELETE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_FAILURE,
  DISABLE_WEBSITE_SUCCESS,
  DISABLE_WEBSITE_FAILURE,
  USER_WEBSITE_STATUS_SUCCESS,
  USER_WEBSITE_STATUS_FAILURE, 
  DUPLICATE_WEBSITE_SUCCESS,
  ADD_NEW_SECTION_FAILURE,ADD_NEW_SECTION_SUCCESS,
  DUPLICATE_WEBSITE_FAILURE, GET_SAVED_TEMPLATE_SUCCESS, GET_SAVED_TEMPLATE_FAILURE, UPDATE_SAVED_TEMPLATE_SUCCESS,
  UPDATE_SAVED_TEMPLATE_FAILURE, ADD_DOMAIN_SUCCESS, ADD_DOMAIN_FAILURE, GET_DOMAIN_SUCCESS, GET_DOMAIN_FAILURE, 
  USE_DOMAIN_SUCCESS, USE_DOMAIN_FAILURE,
  ADD_BUTTON_CLASS_SUCCESS,
  ADD_BUTTON_CLASS_FAILURE,
  WEBSITE_TRANSFER_SUCCESS,
  WEBSITE_TRANSFER_FAILURE,
  DELETE_DOMAIN_SUCCESS, DELETE_DOMAIN_FAILURE, EDIT_DOMAIN_SUCCESS, EDIT_DOMAIN_FAILURE,
  SET_MESSAGE,
} from "./type";



import GrapesjsService from "../services/grapesjs.service";

export const template1 = (data) => (dispatch) => {
  return GrapesjsService.template1(data).then(
    (response) => {
      console.log("response", response);
      dispatch({
        type: TEMPLATE_1_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: TEMPLATE_1_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const getWebsiteByUserandId = (data) => (dispatch) => {
  return GrapesjsService.getWebsiteByUserandId(data).then(
    (response) => {
      dispatch({
        type: USER_WEBSITE_SUCCESS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: USER_WEBSITE_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const variantById = (data) => (dispatch) => {
  return GrapesjsService.variantById(data).then(
    (response) => {
      dispatch({
        type: USER_WEBSITE_PUBLISH_SUCCESS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: USER_WEBSITE_PUBLISH_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const getWebsiteStatus = (data) => (dispatch) => {
  return GrapesjsService.getWebsiteStatus(data).then(
    (response) => {
      dispatch({
        type: USER_WEBSITE_STATUS_SUCCESS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: USER_WEBSITE_STATUS_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const templateSection = (data) => (dispatch) => {
  return GrapesjsService.templateSection(data).then(
    (response) => {
      dispatch({
        type: USER_TEMPLATE_SUCCESS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: USER_TEMPLATE_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const templateColor = (data) => (dispatch) => {
  return GrapesjsService.templateColor(data).then(
    (response) => {
      dispatch({
        type: USER_COLOR_SUCCESS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: USER_COLOR_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const getWebsiteByUserId = (user_id) => (dispatch) => {
  return GrapesjsService.getWebsiteByUserId(user_id).then(
    (response) => {
      dispatch({
        type: WEBSITE_LIST_SUCCESS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: WEBSITE_LIST_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const getPreviewVariant = (user_id) => (dispatch, getState) => {
  return GrapesjsService.getPreviewVariant(user_id).then(
    (response) => {
      dispatch({
        type: PREVIEW_SUCCESS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: PREVIEW_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};


export const getTemplateByIdAndWebsite = (data) => (dispatch) => {
  return GrapesjsService.getTemplateByIdAndWebsite(data).then(
    (response) => {
      dispatch({
        type: TEMPLATE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: TEMPLATE_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const getSavedVariant = (data) => (dispatch) => {
  return GrapesjsService.getSavedVariant(data).then(
    (response) => {
      console.log(response,"response");
      dispatch({
        type: SAVED_TEMPLATE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error,"errrtioerj")
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type: SAVED_TEMPLATE_FAILURE,
        payload: error.response.data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error);
    }
  );
};

export const getAllSavedVariantsData = (variant_id, website_id) => (dispatch) => {
  return GrapesjsService.getAllSavedVariantsData(variant_id, website_id).then(
    (response) => {
      dispatch({
        type: GET_SAVED_TEMPLATE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: GET_SAVED_TEMPLATE_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const revertSavedVariant = (saved_variant_id) => (dispatch) => {
  return GrapesjsService.revertSavedVariant(saved_variant_id).then(
    (response) => {
      dispatch({
        type: UPDATE_SAVED_TEMPLATE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: UPDATE_SAVED_TEMPLATE_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const removeSection = (id,type,classData, userId) => (dispatch) => {
  return GrapesjsService.removeSection(id,type,classData, userId).then(
    (response) => {
      dispatch({
        type: REMOVE_SECTION_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: REMOVE_SECTION_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const updateEditorDataByWebsiteId =
  (id, html, css, projectData) => (dispatch) => {
    return GrapesjsService.updateEditorDataByWebsiteId(
      id,
      html,
      css,
      projectData
    ).then(
      (response) => {
        dispatch({
          type: UPDATE_EDITOR_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: UPDATE_EDITOR_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  };

  export const updateSavedDataInEditor = (id,variantId) => (dispatch) => {
    return GrapesjsService.updateSavedDataInEditor(id,variantId).then(
      (response) => {
        dispatch({
          type: SAVE_EDITOR_SUCCESS,
          payload: response?.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: SAVE_EDITOR_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  }

  export const templateVariants = (data) => (dispatch) => {
    return GrapesjsService.templateVariants(data).then(
      (response) => {
        dispatch({
          type: TEMPLATE_VARIANTS_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: TEMPLATE_VARIANTS_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  };

  export const addNewVariant = (data) => (dispatch) => {
    return GrapesjsService.addNewVariant(data).then(
      (response) => {
        dispatch({
          type: ADD_NEW_VARIANTS_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: ADD_NEW_VARIANTS_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  };

  export const updateVariantsaByWebsiteId =
  (data) => (dispatch) => {
    return GrapesjsService.updateVariantsByWebsiteId(
      data
    ).then(
      (response) => {
        dispatch({
          type: UPDATE_VARIANT_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: UPDATE_VARIANT_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  };

  export const updateVariantTitle =
  (data) => (dispatch) => {
    return GrapesjsService.updateVariantTitle(
      data
    ).then(
      (response) => {
        dispatch({
          type: UPDATE_VARIANT_TITLE_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: UPDATE_VARIANT_TITLE_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  };
  
  export const deleteWebsite =
  (id) => (dispatch) => {
    return GrapesjsService.deleteWebsite(
      id
    ).then(
      (response) => {
        dispatch({
          type: DELETE_WEBSITE_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: DELETE_WEBSITE_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  };

  export const disableWebsite =
  (data) => (dispatch) => {
    return GrapesjsService.disableWebsite(
      data
    ).then(
      (response) => {
        dispatch({
          type: DISABLE_WEBSITE_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: DISABLE_WEBSITE_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  };

  export const duplicateVariant =
  (variant_id) => (dispatch) => {
    return GrapesjsService.duplicateVariant(
      variant_id
    ).then(
      (response) => {
        dispatch({
          type: DUPLICATE_WEBSITE_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: DUPLICATE_WEBSITE_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  };



  export const clickViewCount =
  (data) => (dispatch) => {
    return GrapesjsService.clickViewCount(
      data
    ).then(
      (response) => {
        dispatch({
          type: CLICK_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: CLICK_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  };

  export const saveUserVariant = (data) => (dispatch) => {
    return GrapesjsService.saveUserVariant(data).then(
      (response) => {
        dispatch({
          type: SAVE_USER_VARIANT_SUCCESS,
          payload: response.data.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: SAVE_USER_VARIANT_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  };

  export const addNewSection = (data) => (dispatch) => {
    return GrapesjsService.addNewSection(data).then(
      (response) => {
        dispatch({
          type: ADD_NEW_SECTION_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: ADD_NEW_SECTION_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  }

  export const addDomain = (data) => (dispatch) => {
    return GrapesjsService.addDomain(data).then(
      (response) => {
        dispatch({
          type: ADD_DOMAIN_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: ADD_DOMAIN_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  }

  export const getExistingDomains = (userId) => (dispatch) => {
    return GrapesjsService.getExistingDomains(userId).then(
      (response) => {
        console.log("response", response);
        dispatch({
          type: GET_DOMAIN_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: GET_DOMAIN_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  }

  export const existingDomain = (data) => (dispatch) => {
    return GrapesjsService.existingDomains(data).then(
      (response) => {
        dispatch({
          type: USE_DOMAIN_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: USE_DOMAIN_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  }

  export const deleteDomain = (domainId) => (dispatch) => {
    return GrapesjsService.deleteDomain(domainId).then(
      (response) => {
        dispatch({
          type: DELETE_DOMAIN_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: DELETE_DOMAIN_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  }

  export const editDomain = (data) => (dispatch) => {
    return GrapesjsService.editDomain(data).then(
      (response) => {
        dispatch({
          type: EDIT_DOMAIN_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: EDIT_DOMAIN_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  }
  export const addButtonClass = (data) => (dispatch) => {
    return GrapesjsService.addButtonClass(data).then(
      (response) => {
        dispatch({
          type: ADD_BUTTON_CLASS_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: ADD_BUTTON_CLASS_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  }

  export const websiteTransfer = (data) => (dispatch) => {
    return GrapesjsService.websiteTransfer(data).then(
      (response) => {
        console.log(response,"response");
        dispatch({
          type: WEBSITE_TRANSFER_SUCCESS,
          payload: response.data,
        });
        console.log(response, "response");
        return Promise.resolve(response);
      },
      (error) => {
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type: WEBSITE_TRANSFER_FAILURE,
          payload: error.response.data,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject(error.response.data);
      }
    );
  }
  
