import { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { toast } from "react-toastify";

const useGoogleAuth = (onSuccessCallback, dispatch, googleLoginAction) => {
  const [googleData, setGoogleData] = useState(null);
  const [isGoogleLoading, setGoogleIsLoading] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => setGoogleData(response),
    onError: () => toast.error("Google login failed"),
    scope: "profile email",
  });

  useEffect(() => {
    if (googleData) {
      setGoogleIsLoading(true);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleData.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${googleData.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const userInfo = res.data;
          dispatch(
            googleLoginAction({
              email: userInfo.email,
              name: userInfo.name,
              image: userInfo.picture,
              email_verified: userInfo.verified_email,
              clientId: userInfo.id,
            })
          )
            .then(() => {
              setGoogleIsLoading(false);
              if (onSuccessCallback) onSuccessCallback();
            })
            .catch(() =>{
              setGoogleIsLoading(false);
              toast.error("Google login failed");
            });
        })
        .catch(() =>{
          setGoogleIsLoading(false);
          toast.error("Failed to fetch Google user data")
        });
    }
  }, [googleData, dispatch, googleLoginAction, onSuccessCallback]);

  return {googleLogin, isGoogleLoading};
};

export default useGoogleAuth;
