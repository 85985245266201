export const selectedClasses = [
 'about',
 'testimonial',
 'review-quote-img',
 'header_inner_half',
 'header_inner_bg',
 'main_logo',
 'header_button',
 'header_blue_button',
 'about_button',
 'work_button',
 'benefits_button',
 'service_button',
 'header_button_two',
 'header_blue_button_two',
 'about_button_two',
 'work_button_two',
 'benefits_button_two',
 'service_button_two',
];

export const buttonClasses = [
 'header_button',
 'about_button',
 'work_button',
 'benefits_button',
 'service_button',
];

export const buttonClassesTwo = [
 'header_button_two',
 'about_button_two',
 'work_button_two',
 'benefits_button_two',
 'service_button_two',
];

export const headerClasses = [
 'header_heading',
 'header_mark_icon',
 'about-title',
 'work_title',
 'benefits-title',
 'review-title',
 'service-title',
 'faq-title',
 'header_second_inner',
 'header_button',
 'about_button',
 'work_button',
 'benefits_button',
 'service_button',
 'header_circle_outer_layer',
 'gallery_5_title',
 'gallery_4_title',
 'gallery_3_title',
 'gallery_1_title',
 'pricing_2_title',
 'pricing_1_title',
];

export const textClasses = [
 'header_text',
 'header_mark',
 'header_mark_text',
 'about-text',
 'work-head',
 'work-subHead',
 'work_text',
 'benefits_heading',
 'benefits_text',
 'testimonial__text',
 'testimonial__name',
 'testimonial__job',
 'service-text',
 'faq-heading',
 'faq-text',
 'copyright-text',
 'footer-textone',
 'footer-texttwo',
 'header-content',
 'about-line',
 'work-line',
 'benefits-line',
 'review-line',
 'service-line',
 'faq-line',
 'pricing_2_data',
 'pricing_1_data',
 'td_2_head',
 'td_1_head',
];

export const backgroundClasses = [
 'header_mark_icon',
 'header_second_inner',
 'header_button',
 'about_button',
 'work_button',
 'benefits_button',
 'service_button',
 'header_circle_outer_layer',
];

export const borderClasses = [
 'about-line',
 'work-line',
 'benefits-line',
 'review-line',
 'service-line',
 'faq-line',
 'gallery_1_line',
 'gallery_3_line',
 'gallery_4_line',
 'gallery_5_line',
];

export const ApplyAllClasses = {
 '66791c8be9989056875a9fc9': {
    variant:'v1',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    headerClasses: [
     'header_heading',
     'header_mark_icon',
     'about-title',
     'work_title',
     'benefits-title',
     'review-title',
     'service-title',
     'faq-title',
     'header_second_inner',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
     'gallery_5_title',
     'gallery_4_title',
     'gallery_3_title',
     'gallery_1_title',
     'pricing_2_title',
     'pricing_1_title',
    ],
    textClasses: [
     'header_text',
     'header_mark',
     'header_mark_text',
     'about-text',
     'work-head',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'testimonial__text',
     'testimonial__name',
     'testimonial__job',
     'service-text',
     'faq-heading',
     'faq-text',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'header-content',
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head',
    ],
    backgroundClasses: [
     'header_mark_icon',
     'header_second_inner',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
  },
 '66795727a5bf95261de9af5d': {
    variant:'v5',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    headerClasses: [
     'header_heading',
     'header_mark_icon',
     'about-title',
     'work_title',
     'benefits-title',
     'review-title',
     'service-title',
     'faq-title',
     'header_second_inner',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
     'gallery_5_title',
     'gallery_4_title',
     'gallery_3_title',
     'gallery_1_title',
     'pricing_2_title',
     'pricing_1_title',
    ],
    textClasses: [
     'header_text',
     'header_mark',
     'header_mark_text',
     'about-text',
     'work-head',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'testimonial__text',
     'testimonial__name',
     'testimonial__job',
     'service-text',
     'faq-heading',
     'faq-text',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'header-content',
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head',
    ],
    backgroundClasses: [
     'header_mark_icon',
     'header_second_inner',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
  },
 '66795730a5bf95261de9af5e': {
    variant:'v8',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    headerClasses: [
     'header_heading',
     'header_mark_icon',
     'about-title',
     'work_title',
     'benefits-title',
     'review-title',
     'service-title',
     'faq-title',
     'header_second_inner',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
     'gallery_5_title',
     'gallery_4_title',
     'gallery_3_title',
     'gallery_1_title',
     'pricing_2_title',
     'pricing_1_title',
    ],
    textClasses: [
     'header_text',
     'header_mark',
     'header_mark_text',
     'about-text',
     'work-head',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'testimonial__text',
     'testimonial__name',
     'testimonial__job',
     'service-text',
     'faq-heading',
     'faq-text',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'header-content',
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head',
    ],
    backgroundClasses: [
     'header_mark_icon',
     'header_second_inner',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
  },
 '66795737a5bf95261de9af5f': {
    variant:'v10',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    headerClasses: [
     'header_heading',
     'header_mark_icon',
     'about-title',
     'work_title',
     'benefits-title',
     'review-title',
     'service-title',
     'faq-title',
     'header_second_inner',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
     'gallery_5_title',
     'gallery_4_title',
     'gallery_3_title',
     'gallery_1_title',
     'pricing_2_title',
     'pricing_1_title',
    ],
    textClasses: [
     'header_text',
     'header_mark',
     'header_mark_text',
     'about-text',
     'work-head',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'testimonial__text',
     'testimonial__name',
     'testimonial__job',
     'service-text',
     'faq-heading',
     'faq-text',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'header-content',
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head',
    ],
    backgroundClasses: [
     'header_mark_icon',
     'header_second_inner',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
  },
 '66a38c1512dcd54b2c311ca3': {
    variant:'v2',
    buttonClasses: [
     'header_mark_icon',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    // headerClasses: [],
    otherClasses: [
     'header_heading',
     'header_mark_icon::before',
     'header_mark',
     'header_text',
     'header_mark_text',
     'header-content',
     'testimonial__text',
     'testimonial__name',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    textClasses: [
     'about-title',
     'about-text',
     'benefits-title',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'service-title',
     'service-text',
     'faq-heading',
     'faq-text',
     'faq-title',
     'review-title',
     'work_title',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head',
    ],
    backgroundClasses: [
     'header_mark_icon',
     'header_second_inner',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
    background_other_classes: ['header_section_inner_gradient','container-testimonials'],
  },
 '66a9f5affed51126e037cb2c': {
    variant:'v6',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
     headerClasses: [
     'header_heading',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_img_bg',
     'about-image-bg',
     'star_icon',
     'benefits-image-bg',
     'benefits-image-reverse-bg'
    ],
    otherClasses: [
      
    ],
    textClasses: [
     'header_text',
     'about-title',
     'about-text',
     'benefits-title',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'service-title',
     'service-text',
     'faq-heading',
     'faq-text',
     'faq-title',
     'review-title',
     'work_title',
     'work-subHead-gradient',
     'work_text_gradient',
     'testimonial__name_normal',
     'testimonial__job',
     'testimonial__text_normal',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head',
    ],
    backgroundClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    borderClasses: [
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
    background_other_classes: ['header_img_bg',
   'about-image-bg',
   'star_icon',
   'benefits-image-bg',
   'benefits-image-reverse-bg'],
  },
 '66b0c6ca13c9833addf7787b': {
    variant:'v4',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    headerClasses: [
     'header_heading',
     'header_mark_icon',
     'work-head',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    textClasses: [
     'header_text',
     'header_mark',
     'header_mark_text',
     'benefits-title',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'testimonial__text_normal',
     'testimonial__name_normal',
     'testimonial__job',
     'service-title',
     'service-text',
     'faq-heading',
     'faq-text',
     'header-content',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head',
    ],
    backgroundClasses: [
     'header_mark_icon',
     'header_second_inner',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
    otherClasses: [
     'header_section_inner',
     'header_mark_icon::before',
     'about-title',
     'about-text',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'benefits',
     'work_title',
     'howItWork',
     'review-title',
     'testimonial',
     'service_section_inner',
     'faq label',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
    ],
  },
 '668fc9f230ecbf166cbd7a2e': {
    variant:'v3',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    headerClasses: [
     'header_heading',
     'header_mark_icon',
     'about-title',
     'work_title',
     'benefits-title',
     'review-title',
     'service-title',
     'faq-title',
     'header_second_inner',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
     'gallery_5_title',
     'gallery_4_title',
     'gallery_3_title',
     'gallery_1_title',
     'pricing_2_title',
     'pricing_1_title',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'
    ],
    textClasses: [
     'header_text',
     'header_mark',
     'header_mark_text',
     'about-text',
     'work-head',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'testimonial__text_grid',
     'service-text',
     'faq-heading',
     'faq-text',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'header-content',
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head', 
    ],
    backgroundClasses: [
     'header_mark_icon',
     'header_button',
     'about_button',
     'work_button',
     'service_button',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'

    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
  },
  '6690e45130ecbf166cbd7a32': {
    variant:'v7',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    headerClasses: [
     'header_heading',
     'header_mark_icon',
     'about-title',
     'work_title',
     'benefits-title',
     'review-title',
     'service-title',
     'faq-title',
     'header_second_inner_bg_gradiant',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
     'gallery_5_title',
     'gallery_4_title',
     'gallery_3_title',
     'gallery_1_title',
     'pricing_2_title',
     'pricing_1_title',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'
    ],
    textClasses: [
     'header_text',
     'header_mark',
     'header_mark_text',
     'about-text',
     'work-head',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'testimonial__text_grid',
     'service-text',
     'faq-heading',
     'testimonial__name',
     'faq-text',
     'step-line',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'header-content',
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head', 
    ],
    backgroundClasses: [
     'header_mark_icon',
     'header_second_inner_bg_gradiant',
     'header_button',
     'about_button',
     'work_button',
     'service_button',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'

    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'step-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
  },
  '6690e45a30ecbf166cbd7a33': {
    variant:'v7',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    headerClasses: [
     'header_heading',
     'header_mark_icon',
     'about-title',
     'work_title',
     'benefits-title',
     'review-title',
     'service-title',
     'faq-title',
     'gradiant-square-top',
     'gradiant-square-bottom',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
     'gallery_5_title',
     'gallery_4_title',
     'gallery_3_title',
     'gallery_1_title',
     'pricing_2_title',
     'pricing_1_title',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'
    ],
    textClasses: [
     'header_text',
     'header_mark',
     'header_mark_text',
     'about-text',
     'work-head',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'testimonial__text_grid',
     'service-text',
     'faq-heading',
     'step-line',
     'testimonial__name',
     'faq-text',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'header-content',
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head', 
    ],
    backgroundClasses: [
     'header_mark_icon',
     'gradiant-square-top',
     'gradiant-square-bottom',
     'header_button',
     'about_button',
     'work_button',
     'service_button',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'

    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'step-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
  },
  '6690e45f30ecbf166cbd7a34': {
    variant:'v11',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    headerClasses: [
     'header_heading',
     'header_mark_icon',
     'about-title',
     'work_title',
     'benefits-title',
     'review-title',
     'service-title',
     'faq-title',
     'gradiant-square-top',
     'gradiant-square-bottom',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
     'gallery_5_title',
     'gallery_4_title',
     'gallery_3_title',
     'gallery_1_title',
     'pricing_2_title',
     'pricing_1_title',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'
    ],
    textClasses: [
     'header_text',
     'header_mark',
     'header_mark_text',
     'about-text',
     'work-head',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'testimonial__text',
     'service-text',
     'faq-heading',
     'step-line',
     'testimonial__name',
     'faq-text',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'header-content',
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head', 
    ],
    backgroundClasses: [
     'header_mark_icon',
     'gradiant-square-top',
     'gradiant-square-bottom',
     'header_button',
     'about_button',
     'work_button',
     'service_button',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'

    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'step-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
  },
  '66910fe0248aa0b27f3f5243': {
    variant:'v12',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    headerClasses: [
     'header_heading',
     'header_mark_icon',
     'about-title',
     'work_title',
     'benefits-title',
     'review-title',
     'service-title',
     'faq-title',
     'header_second_inner_bg_gradiant',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
     'gallery_5_title',
     'gallery_4_title',
     'gallery_3_title',
     'gallery_1_title',
     'pricing_2_title',
     'pricing_1_title',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'
    ],
    textClasses: [
     'header_text',
     'header_mark',
     'header_mark_text',
     'about-text',
     'work-head',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'testimonial__text',
     'service-text',
     'faq-heading',
     'step-line',
     'testimonial__name',
     'faq-text',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'header-content',
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head', 
    ],
    backgroundClasses: [
     'header_mark_icon',
     'header_second_inner_bg_gradiant',
     'header_button',
     'about_button',
     'work_button',
     'service_button',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'

    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'step-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
  },
  '66911015248aa0b27f3f5244': {
    variant:'v13',
    buttonClasses: [
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
    ],
    buttonClassesTwo: [
     'header_button_two',
     'about_button_two',
     'work_button_two',
     'benefits_button_two',
     'service_button_two',
    ],
    headerClasses: [
     'header_heading',
     'header_mark_icon',
     'about-title',
     'work_title',
     'benefits-title',
     'review-title',
     'service-title',
     'faq-title',
     'header_button',
     'about_button',
     'work_button',
     'benefits_button',
     'service_button',
     'header_circle_outer_layer',
     'gallery_5_title',
     'gallery_4_title',
     'gallery_3_title',
     'gallery_1_title',
     'pricing_2_title',
     'pricing_1_title',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'
    ],
    textClasses: [
     'header_text',
     'header_mark',
     'header_mark_text',
     'about-text',
     'work-head',
     'work-subHead',
     'work_text',
     'benefits_heading',
     'benefits_text',
     'testimonial__text',
     'service-text',
     'faq-heading',
     'step-line',
     'testimonial__name',
     'faq-text',
     'copyright-text',
     'footer-textone',
     'footer-texttwo',
     'header-content',
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'pricing_2_data',
     'pricing_1_data',
     'td_2_head',
     'td_1_head', 
    ],
    backgroundClasses: [
     'header_mark_icon',
     'header_button',
     'about_button',
     'work_button',
     'service_button',
     'testimonial_outer',
     'benefits_card::before',
     'faq input[type="checkbox"]:checked + label .faq-heading'

    ],
    borderClasses: [
     'about-line',
     'work-line',
     'benefits-line',
     'review-line',
     'service-line',
     'faq-line',
     'step-line',
     'gallery_1_line',
     'gallery_3_line',
     'gallery_4_line',
     'gallery_5_line',
    ],
  },
};
